<script lang="ts" setup>
  import { computed } from 'vue'

  import { FetchErrorObj, Nullable, ShortTimeString } from '@algorh/shared'
  import { AlgSelect, AlgTimeInput } from '@algorh/ui'

  import { RuleDurationSelect, RuleSubjectSelect } from '@/components/rules-ambitions'
  import { RuleComparison, RuleMesh, RuleReference, RuleTiming } from '@/core/enums/Rule'

  import { RuleDto } from '@/sections/settings/services'

  import { selectOptions } from '../ruleForms'

  type Model = Pick<RuleDto, 'config' | 'subject_id' | 'subject_type' | 'value'>

  type Props = {
    readonly errors: Nullable<FetchErrorObj<Model>>
    readonly activityFamilies: { id: number, name: string }[]
    readonly activities: { id: number, name: string }[]
  }

  const props = defineProps<Props>()

  const model = defineModel<Model>({ required: true })

  const subject = computed(() => ({ type: model.value.subject_type, id: model.value.subject_id }))

  const hasFirstReference = computed(() => model.value.config?.timing ? [RuleTiming.BEFORE, RuleTiming.AFTER, RuleTiming.BETWEEN].includes(model.value.config.timing) : false)

  const hasFirsTime = computed(() => hasFirstReference.value && model.value.config?.first_reference === RuleReference.TIME)

  const hasSecondReference = computed(() => model.value.config?.timing === RuleTiming.BETWEEN)

  const hasSecondTime = computed(() => hasSecondReference.value && model.value.config?.second_reference === RuleReference.TIME)

  function handleComparisonUpdate(comparison: Nullable<RuleComparison>) {
    if (!model.value.config) {
      return
    }

    model.value = {
      ...model.value,
      config: { ...model.value.config, comparison: comparison ?? undefined },
    }
  }

  function handleTimingUpdate(timing: Nullable<RuleTiming>) {
    if (!model.value.config) {
      return
    }

    model.value = {
      ...model.value,
      config: {
        ...model.value.config,
        timing: timing ?? undefined,
        mesh: model.value.config?.mesh ?? RuleMesh.DAY,
        first_reference: model.value.config.first_reference ?? RuleReference.LUNCH,
        second_reference: model.value.config.second_reference ?? RuleReference.LUNCH,
      },
    }
  }

  function handleMeshUpdate(mesh: Nullable<RuleMesh>) {
    if (!model.value.config) {
      return
    }
    model.value = {
      ...model.value,
      config: { ...model.value.config, mesh: mesh ?? undefined },
    }
  }

  function handleFirstReferenceUpdate(first_reference: Nullable<RuleReference>) {
    if (!model.value.config) {
      return
    }
    model.value = {
      ...model.value,
      config: {
        ...model.value.config,
        first_reference: first_reference ?? undefined,
        first_time: first_reference === RuleReference.LUNCH ? undefined : '12:00',
      },
    }
  }

  function handleFirstTimeUpdate(value: Nullable<ShortTimeString>) {
    if (!model.value.config) {
      return
    }

    model.value = {
      ...model.value,
      config: { ...model.value.config, first_time: value ?? undefined },
    }
  }

  function handleSecondReferenceUpdate(second_reference: Nullable<RuleReference>) {
    if (!model.value.config) {
      return
    }
    model.value = {
      ...model.value,
      config: {
        ...model.value.config,
        second_reference: second_reference ?? undefined,
        second_time: second_reference === RuleReference.LUNCH ? undefined : '12:00',
      },
    }
  }

  function handleDurationUpdate(v: Nullable<number>) {
    model.value = { ...model.value, value: v ?? 0 }
  }

  function handleSubjectUpdate(s: typeof subject.value) {
    model.value = {
      ...model.value,
      subject_type: s.type,
      subject_id: s.id,
    }
  }

  function handleSecondTimeUpdate(value: Nullable<ShortTimeString>) {
    if (!model.value.config) {
      return
    }

    model.value = {
      ...model.value,
      config: { ...model.value.config, second_time: value ?? undefined },
    }
  }
</script>

<template>
  <div class="rule-form">
    <RuleDurationSelect
      name="value"
      :model-value="model.value"
      class="choice-fields has-counter"
      :errors="errors?.errors?.value"
      @update:model-value="handleDurationUpdate"
    />
    <AlgSelect
      id="config-comparison"
      class="choice-fields has-counter"
      centered
      required
      :options="selectOptions('rules', Object.values(RuleComparison))"
      :errors="errors?.errors?.['config.comparison']"
      :model-value="model.config?.comparison ?? null"
      @update:model-value="handleComparisonUpdate"
    />
    <RuleSubjectSelect
      class="choice-fields has-counter"
      nullable
      :activity-families="props.activityFamilies"
      :activities="props.activities"
      :errors="errors?.errors?.subject_id"
      :model-value="subject"
      @update:model-value="handleSubjectUpdate"
    />
    <AlgSelect
      id="config-timing"
      class="choice-fields has-counter"
      centered
      required
      :options="selectOptions('rules', Object.values(RuleTiming))"
      :errors="errors?.errors?.['config.timing']"
      :model-value="modelValue.config?.timing ?? null"
      @update:model-value="handleTimingUpdate"
    />
    <AlgSelect
      v-if="modelValue.config?.timing === 'during'"
      id="config-mesh"
      class="choice-fields has-counter"
      centered
      required
      :options="selectOptions('rules', Object.values(RuleMesh))"
      :errors="errors?.errors?.['config.mesh']"
      :model-value="model.config?.mesh ?? null"
      @update:model-value="handleMeshUpdate"
    />
    <AlgSelect
      v-if="hasFirstReference"
      id="config-first_reference"
      class="choice-fields has-counter"
      centered
      required
      :options="selectOptions('rules', Object.values(RuleReference))"
      :errors="errors?.errors?.['config.first_reference']"
      :model-value="model.config?.first_reference ?? null"
      @update:model-value="handleFirstReferenceUpdate"
    />
    <AlgTimeInput
      v-if="hasFirsTime"
      id="config.first_time"
      :model-value="model.config?.first_time ?? null"
      :errors="errors?.errors?.['config.first_time']"
      centered
      @update:model-value="handleFirstTimeUpdate"
    />
    <AlgSelect
      v-if="hasSecondReference"
      id="config-second_reference"
      class="choice-fields has-counter"
      centered
      required
      :options="selectOptions('rules', Object.values(RuleReference))"
      :errors="errors?.errors?.['config.second_reference']"
      :model-value="model.config?.second_reference ?? null"
      @update:model-value="handleSecondReferenceUpdate"
    />
    <AlgTimeInput
      v-if="hasSecondTime"
      id="config.second_time"
      :model-value="model.config?.second_time ?? null"
      :errors="errors?.errors?.['config.second_time']"
      centered
      @update:model-value="handleSecondTimeUpdate"
    />
  </div>
</template>

<style src="@/components/rules-ambitions/rules/forms/ruleForms.css" />
