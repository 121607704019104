<script setup lang="ts">
  import { computed, ref } from 'vue'

  import { FloatingPlacement, useFloating } from '@algorh/shared'

  type Props = {
    readonly id?: string
    readonly placement?: FloatingPlacement
    readonly permanent?: boolean
    readonly visible?: boolean
  }

  defineOptions({
    name: 'AlgTooltip',
  })

  const props = withDefaults(defineProps<Props>(), {
    placement: () => 'top',
    strategy: 'fixed',
    permanent: false,
    visible: true,
  })

  const emit = defineEmits<{
    (e: 'open'): void
    (e: 'close'): void
    (e: 'focus'): void
    (e: 'blur'): void
  }>()

  const content = ref<HTMLElement | null>(null)

  // Composables
  const {
    open,
    isOpen,
    floating,
    reference,
    computedPosition,
    correctedPlacement,
  } = useFloating(props.placement, false, 12)

  const permanentProp = computed(() => props.permanent || isOpen.value)

  const contentPosition = computed(() => {
    if (!computedPosition || !content.value) {
      return
    }

    const { x, y } = computedPosition.value

    let position = { x: 0, y: 0 }

    const { height, width } = content.value?.getBoundingClientRect()

    const maxX = window.innerWidth - width
    const maxY = window.innerHeight - height

    switch (correctedPlacement.value) {
    case 'top':
      position = {
        x: x - width / 2 + 8,
        y: y - height,
      }
      break
    case 'bottom':
      position = {
        x: x - width / 2 + 8,
        y: y + 8,
      }
      break
    case 'left':
      position = {
        x: x - width,
        y: y - height / 2 + 8,
      }
      break
    case 'right':
      position = {
        x: x + 8,
        y: y - height / 2 + 8,
      }
      break
    }
    return {
      x: Math.min(Math.max(0, position.x), maxX),
      y: Math.min(Math.max(0, position.y), maxY),
    }
  })

  // Methods
  function onOpen() {
    open()

    if (permanentProp.value) {
      return
    }

    emit('open')
  }

  function close() {
    if (!isOpen.value) {
      return
    }
    isOpen.value = false

    if (permanentProp.value) {
      return
    }

    emit('close')
  }

  function toggle() {
    isOpen.value ? close() : onOpen()
  }

  function handleMouseOver() {
    if (isOpen.value || !props.visible) {
      return
    }
    onOpen()
  }

  function handleMouseOut() {
    if (!isOpen.value) {
      return
    }
    close()
  }

  function handleFocus() {
    onOpen()
    emit('focus')
  }

  function handleBlur() {
    close()
    emit('blur')
  }
</script>

<template>
  <div class="tooltip">
    <div
      ref="reference"
      class="reference"
      :aria-describedby="id"
      tabindex="0"
      role="button"
      @mouseover="handleMouseOver"
      @mouseout="handleMouseOut"
      @focus="handleFocus"
      @blur="handleBlur"
    >
      <slot
        name="reference"
        v-bind="{ open, isOpen, close, onOpen, toggle }"
      />
    </div>
    <Teleport
      v-if="(isOpen || permanentProp) && props.visible"
      to="body"
    >
      <div
        :id="id"
        ref="floating"
        class="arrow"
        :class="correctedPlacement"
        :style="{
          top: `${computedPosition?.y}px`,
          left: `${computedPosition?.x}px`
        }"
      />
      <div
        ref="content"
        class="content"
        :style="{
          top: `${contentPosition?.y}px`,
          left: `${contentPosition?.x}px`
        }"
      >
        <slot
          v-if="permanentProp"
          name="content"
          v-bind="{ isOpen, close, onOpen, toggle }"
        />
      </div>
    </teleport>
  </div>
</template>

<style scoped>
.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reference {
  position: relative;
  display: flex;
  min-width: 0;
  min-height: 0;
  flex: 1 1 auto;
}

.arrow {
  position: fixed;
  z-index: 80;
  width: 0;
  height: 0;

  &.top {
    border-top: var(--alg-spacing-s) solid var(--alg-color-icon-primary);
    border-right: var(--alg-spacing-s) solid var(--alg-color-transparent);
    border-left: var(--alg-spacing-s) solid var(--alg-color-transparent);
  }

  &.bottom {
    border-right: var(--alg-spacing-s) solid var(--alg-color-transparent);
    border-bottom: var(--alg-spacing-s) solid var(--alg-color-icon-primary);
    border-left: var(--alg-spacing-s) solid var(--alg-color-transparent);
  }

  &.left {
    border-top: var(--alg-spacing-s) solid var(--alg-color-transparent);
    border-bottom: var(--alg-spacing-s) solid var(--alg-color-transparent);
    border-left: var(--alg-spacing-s) solid var(--alg-color-icon-primary);
  }

  &.right {
    border-top: var(--alg-spacing-s) solid var(--alg-color-transparent);
    border-right: var(--alg-spacing-s) solid var(--alg-color-icon-primary);
    border-bottom: var(--alg-spacing-s) solid var(--alg-color-transparent);
  }
}

.content {
  position: fixed;
  z-index: 80;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: var(--alg-effect-radius-m);
  background-color: var(--alg-color-icon-primary);
  color: var(--alg-color-text-on-color);
  font-size: var(--alg-font-size-m);
  font-weight: var(--alg-font-weight-regular);
  padding-block: var(--alg-spacing-s);
  padding-inline: var(--alg-spacing-m);
  text-align: center;
}
</style>
