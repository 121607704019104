<script setup lang="ts" generic="T extends { id: string | number }, V extends (string | number)[]">
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { FloatingPlacement, Nullable, useIsMobile } from '@algorh/shared'

  import { AlgTooltip } from '#/components/tooltip'

  import { AlgButton } from '../../../button'
  import { AlgErrors } from '../../../feedback'
  import { AlgIcon } from '../../../media'
  import { AlgPopper } from '../../../popover'
  import { AlgChoiceTree } from '../../choice-tree'
  import { ChoiceTreeData } from '../../choice-tree/ChoiceTree.type'
  import { AlgLabel } from '../../label'
  import { SelectSize, SelectVariant } from '../select/Select.type'

  type Props = {
    readonly id: string
    readonly data: ChoiceTreeData<T>[]
    readonly label?: string
    readonly sublabel?: string
    readonly placeholder?: string
    readonly noneLabel?: string
    readonly size?: SelectSize
    readonly variant?: SelectVariant
    readonly inline?: boolean
    readonly required?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    readonly centered?: boolean
    readonly searchable?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
    readonly placement?: FloatingPlacement
    readonly multiple?: boolean
    readonly recursiveSelect?: boolean
    readonly parentSelected?: boolean
    readonly labelHierarchy?: boolean
    readonly tooltip?: boolean
  }

  defineOptions({
    name: 'AlgSelect',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 'm',
    variant: 'primary',
    inline: false,
    required: false,
    disabled: false,
    readonly: false,
    centered: false,
    searchable: false,
    errored: false,
    placement: () => 'bottom-start',
    multiple: false,
    recursiveSelect: false,
    parentSelected: false,
    labelHierarchy: true,
  })

  const model = defineModel<V>({ required: true })

  // Composables
  const { t } = useI18n()

  const isMobile = useIsMobile()

  // Refs
  const input = ref<Nullable<HTMLDivElement>>(null)

  const query = ref('')

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  const filteredData = computed(() => {
    if (!props.searchable || !query.value) {
      return props.data
    }

    const filter = (data: ChoiceTreeData<T>[], query: string): ChoiceTreeData<T>[] => {
      return data.reduce((acc, item) => {
        if (item.name.toLowerCase().includes(query.toLowerCase())) {
          acc.push(item)
        }

        if (item.children) {
          const children = filter(item.children, query)

          if (children.length > 0) {
            acc.push({ ...item, children })
          }
        }

        return acc
      }, [] as ChoiceTreeData<T>[])
    }

    return filter(props.data, query.value)
  })

  const computedLabel = computed(() => {
    if (props.multiple) {
      const labels = model.value.map((id) => findLabel(props.data, id))

      if (labels.length === 0) {
        return props.noneLabel ?? ''
      }

      return labels.length > 1
        ? `${labels[0]} ${t('common.+ {n} other', { n: labels.length - 1 }, labels.length - 1)}`
        : labels[0]
    }

    return findLabel(props.data, model.value[0])
  })

  // Methods
  function findLabel(data: ChoiceTreeData<T>[], id: string | number): string {
    for (const item of data) {
      if (item.id === id) {
        return item.name
      }

      if (item.children) {
        const label = findLabel(item.children, id)

        if (label) {
          return !props.multiple && props.labelHierarchy ? `${item.name} / ${label}` : label
        }
      }
    }

    return ''
  }

  function handleSearch(e: Event) {
    query.value = (e.target as HTMLInputElement).value
  }

  function handleClearSearch() {
    query.value = ''
  }

  function handleFocus(open: () => void) {
    if (props.disabled) {
      return
    }

    open()
  }

  function handleToggle(toggle: () => void) {
    if (props.disabled) {
      return
    }

    toggle()
  }

  function handlePopperToggle(isOpen: boolean, toggle: () => void) {
    toggle()

    if (!isMobile.value && !isOpen && props.searchable && input.value) {
      input.value.focus()
    }
  }

  function handlePopperClose() {
    handleClearSearch()
  }
</script>

<template>
  <AlgTooltip
    :id="`tooltip-${props.id}`"
    :placement="'top'"
    :visible="props.tooltip"
  >
    <template #content>
      {{ computedLabel }}
    </template>
    <template #reference>
      <div
        class="field-wrapper"
        :class="{ inline: props.inline }"
      >
        <AlgLabel
          v-if="props.label"
          :label="props.label"
          :sublabel="props.sublabel"
          :html-for="props.id"
          :inline="props.inline"
          :input-size="props.size"
          :required="props.required"
          :errored="hasErrors"
        />
        <div class="field-content">
          <div
            class="input-wrapper"
            :class="{ disabled: props.disabled, [props.variant]: true }"
          >
            <AlgPopper
              :placement="placement"
              strategy="absolute"
              full-width
              @close="handlePopperClose"
            >
              <template #reference="{ isOpen, open, toggle }">
                <input
                  v-if="props.searchable"
                  :id="props.id"
                  ref="input"
                  class="input"
                  :class="[`size-${props.size}`, { errored: hasErrors, centered: props.centered }]"
                  :name="props.id"
                  autocomplete="off"
                  :placeholder="isOpen ? t('common.Search') : props.placeholder ?? t('common.Select')"
                  :disabled="props.disabled"
                  :readonly="props.readonly"
                  :value="isOpen ? query : computedLabel"
                  @focus="() => handleFocus(open)"
                  @input="(e) => handleSearch(e)"
                >
                <button
                  v-else
                  type="button"
                  class="input fake-input"
                  :class="[`size-${props.size}`, { errored: hasErrors, centered: props.centered }]"
                  :disabled="props.disabled"
                  @click="() => handleToggle(toggle)"
                >
                  <span
                    v-if="computedLabel"
                    class="label"
                  >
                    {{ computedLabel }}
                  </span>
                  <span
                    v-else
                    class="placeholder"
                  >
                    {{ props.placeholder ?? t('common.Select') }}
                  </span>
                </button>
                <span
                  v-if="isOpen && query"
                  class="clear-search"
                >
                  <button
                    type="button"
                    :title="t('common.Clear')"
                    @click="handleClearSearch"
                  >
                    <AlgIcon
                      name="cancel"
                      size="s"
                    />
                  </button>
                </span>
                <span class="select-arrow">
                  <button
                    type="button"
                    :disabled="props.disabled"
                    :title="isOpen ? t('common.Close') : t('common.Open')"
                    @click="() => handlePopperToggle(isOpen, toggle)"
                  >
                    <AlgIcon :name="isOpen ? 'expand-less' : 'expand-more'" />
                  </button>
                </span>
              </template>
              <template #content>
                <div class="popper-content-wrapper">
                  <template v-if="filteredData.length">
                    <AlgChoiceTree
                      v-for="item in filteredData"
                      :key="item.id"
                      v-model="model"
                      class="choice-tree"
                      :readonly="props.readonly"
                      :multiple="props.multiple"
                      :recursive-select="props.recursiveSelect"
                      :parent-selected="props.parentSelected"
                      :data="item"
                      @update:model-value="(value) => model = (value as V)"
                    />
                    <AlgButton
                      v-if="props.multiple && model.length"
                      class="action-button"
                      size="s"
                      variant="link"
                      :label="t('common.Clear selection')"
                      @click="() => model = ([] as unknown as V)"
                    />
                  </template>
                  <p
                    v-else
                    class="no-results-message"
                  >
                    {{ query ? t('common.No results') : t('common.No options available') }}
                  </p>
                </div>
              </template>
            </AlgPopper>
          </div>
          <AlgErrors
            v-if="props.errors && props.errors.length"
            :errors="props.errors"
          />
        </div>
      </div>
    </template>
  </AlgTooltip>
</template>

<style src="../index.css" scoped />

<style scoped>
  .field-wrapper {
    display: flex;
    flex: 1 1 auto;

    .field-content {
      flex: 1 1 auto;

      .input-wrapper {
        &.secondary {
          .input {
            border: 1px solid var(--alg-color-surface-secondary);
            background-color: var(--alg-color-surface-secondary);
          }

          .select-arrow {
            button {
              background-color: var(--alg-color-surface-secondary);
              color: var(--alg-color-text-primary);
            }
          }
        }

        .input {
          padding-right: calc(var(--alg-spacing-xl) + var(--alg-spacing-s));
        }

        .clear-search {
          position: absolute;
          top: 50%;
          right: calc(var(--alg-spacing-xl) + var(--alg-spacing-s));
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(-50%);

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            color: var(--alg-color-icon-unselected);

            &:hover {
              color: var(--alg-color-icon-secondary);
            }
          }
        }

        .select-arrow {
          position: absolute;
          top: 50%;
          right: var(--alg-spacing-s);
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(-50%);

          button {
            width: 24px;
            height: 24px;
            padding: 0;
            border-radius: var(--alg-effect-radius-m);
            background-color: var(--alg-color-button-primary);
            color: var(--alg-color-text-on-color);

            &:focus-visible {
              background-color: var(--alg-color-button-primary-hover);
              outline: none;
            }
          }
        }

        &.disabled {
          .select-arrow {
            button {
              background-color: var(--alg-color-button-primary-disabled);
            }
          }

          .input {
            .label {
              color: var(--alg-color-text-light);
            }
          }
        }
      }
    }

    .popper-content-wrapper {
      display: flex;
      max-height: 400px;
      box-sizing: border-box;
      flex-direction: column;
      padding: var(--alg-spacing-xs);
      border: 1px solid var(--alg-color-surface-border);
      border-radius: var(--alg-effect-radius-m);
      background-color: var(--alg-color-surface-primary);
      box-shadow: var(--alg-effect-shadow-m);
      gap: var(--alg-spacing-xs);
      overflow-y: auto;

      .no-results-message {
        padding: var(--alg-spacing-s);
        margin: 0;
        color: var(--alg-color-text-secondary);
        font-size: var(--alg-font-size-s);
        text-align: center;
      }

      .action-button {
        flex: 0 0 auto;
        align-self: flex-end;
      }
    }
  }
</style>
