import { ref } from 'vue'

import { useEventListener } from './useEventListener'

export function useMatchMedia(query: string) {
  const mediaQueryList = ref<MediaQueryList>(matchMedia(query))
  const matches = ref(mediaQueryList.value.matches)

  const process = (e: MediaQueryListEvent) => {
    matches.value = e.matches
  }

  useEventListener('change', process, mediaQueryList.value)

  return {
    mediaQueryList,
    matches,
  }
}
