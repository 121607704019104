import { DateString, DateTimeString, fetchJson } from '@algorh/shared'

import { RiskMesh } from '@/core/enums/Planning'

import { Risk } from '../types/Planning'

const BASE_URI = '/api/scheduling/risks'

const SchedulingRisksApiService = {
  putRiskMesh(riskId: number, mesh: RiskMesh) {
    return fetchJson<Risk, { mesh: RiskMesh }>(
      'PUT', `${BASE_URI}/${riskId}/mesh`, { mesh },
    )
  },
  putRisk(riskId: number, value: number, datetime: DateString | DateTimeString) {
    return fetchJson<Risk, { value: number, datetime: DateString | DateTimeString }>(
      'PUT', `${BASE_URI}/${riskId}/value`, { value, datetime },
    )
  },
}

export { SchedulingRisksApiService }
