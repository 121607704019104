<script lang="ts" setup>
  import { computed } from 'vue'

  import { FetchErrorObj, Nullable } from '@algorh/shared'

  import { RuleDto } from '@/sections/settings/services'

  import RuleDurationSelect from '../../inputs/RuleDurationSelect.vue'
  import RuleSubjectSelect from '../../inputs/RuleSubjectSelect.vue'

  type Model = Pick<RuleDto, 'subject_id' | 'subject_type' | 'value'>
  type Props = {
    readonly errors: Nullable<FetchErrorObj<Model>>
    readonly activityFamilies: { id: number, name: string }[]
    readonly activities: { id: number, name: string }[]
  }

  const props = defineProps<Props>()

  const model = defineModel<Pick<RuleDto, | 'subject_id' | 'subject_type' | 'value'>>({ required: true })

  const subject = computed(() => ({ type: model.value.subject_type, id: model.value.subject_id }))

  function handleValueUpdate(value: Nullable<number>) {
    model.value = { ...model.value, value: value ?? 0 }
  }

  function handleSubjectUpdate(s: typeof subject.value) {
    model.value = {
      ...model.value,
      subject_type: s.type,
      subject_id: s.id,
    }
  }

</script>

<template>
  <div class="rule-form">
    <RuleDurationSelect
      name="value"
      class="choice-fields has-counter"
      :errors="errors?.errors?.value"
      :model-value="model.value"
      @update:model-value="handleValueUpdate"
    />
    <RuleSubjectSelect
      name="subject"
      nullable
      class="choice-fields has-counter"
      :activity-families="props.activityFamilies"
      :activities="props.activities"
      :errors="errors?.errors?.subject_id"
      :model-value="subject"
      @update:model-value="handleSubjectUpdate"
    />
  </div>
</template>

<style src="@/components/rules-ambitions/rules/forms/ruleForms.css" />
