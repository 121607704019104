<script setup lang="ts">
  import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { useHotkey } from '@algorh/shared'

  import { ButtonType, ButtonVariant } from '#/types'

  import { AlgButton } from '../button'
  import { AlgIcon } from '../media'

  import { ModalSize } from './size'

  type Props = {
    readonly allowCancel?: boolean
    readonly allowClose?: boolean
    readonly ariaDescribedBy?: string
    readonly ariaLabelledBy?: string
    readonly borderedFooter?: boolean
    readonly borderedHeader?: boolean
    readonly cancelButtonDisabled?: boolean
    readonly cancelButtonDisplayed?: boolean
    readonly cancelButtonText?: string
    readonly cancelButtonVariant?: ButtonVariant
    readonly closeButtonDisabled?: boolean
    readonly confirmButtonDisabled?: boolean
    readonly confirmButtonLoading?: boolean
    readonly confirmButtonText?: string
    readonly confirmButtonVariant?: ButtonVariant
    readonly confirmButtonType?: ButtonType
    readonly footer?: boolean
    readonly height?: number
    readonly name: string
    readonly size?: ModalSize
    readonly width?: number
    readonly noPadding?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    allowCancel: true,
    allowClose: true,
    ariaDescribedBy: 'modal-content',
    ariaLabelledBy: 'modal-header-title',
    borderedFooter: false,
    borderedHeader: true,
    cancelButtonDisabled: false,
    cancelButtonDisplayed: true,
    cancelButtonVariant: 'secondary',
    closeButtonDisabled: false,
    confirmButtonDisabled: false,
    confirmButtonLoading: false,
    confirmButtonType: 'button',
    footer: true,
    formId: '',
    width: 0,
    height: 0,
    noPadding: false,
  })

  const emit = defineEmits<{
    (e: 'cancel'): void
    (e: 'confirm'): void
    (e: 'close'): void
  }>()

  // Composables
  const { t } = useI18n()

  useHotkey('Escape', () => handleClose())

  // Refs
  const dialog = ref<HTMLDialogElement>()

  const isFullScreen = ref(false)

  // Computed
  const customStyle = computed(() => ({
    width: isFullScreen.value ? '100vw' : props.width ? `${props.width}px` : '',
    height: isFullScreen.value ? '100vh' : props.height ? `${props.height}px` : '',
    maxHeight: isFullScreen.value ? '100vh' : '',
    borderRadius: isFullScreen.value ? '0' : 'var(--alg-effect-radius-l)',
  }))

  // Methods
  function handleResize() {
    if (!props.width) {
      return
    }

    isFullScreen.value = props.width > window.innerWidth
  }

  function handleCancel() {
    emit('cancel')
  }

  function handleConfirm() {
    if (props.confirmButtonLoading) {
      return
    }

    emit('confirm')
  }

  function handleClose() {
    if (!props.allowClose || props.confirmButtonLoading || !dialog.value) {
      return
    }

    dialog.value.close()

    emit('close')
  }

  // Hooks
  onBeforeMount(() => {
    const targetElement = document.getElementById('modals')

    if (!targetElement) {
      throw Error('Element with id "modals" is missing')
    }
  })

  onMounted(() => {
    if (dialog.value) {
      dialog.value.showModal()

      dialog.value.addEventListener('mousedown', (e) => {
        if (e.target === e.currentTarget) {
          handleClose()
        }
      })
    }

    window.addEventListener('resize', handleResize)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)
  })
</script>

<template>
  <Teleport to="#modals">
    <dialog
      ref="dialog"
      class="modal"
      :class="[
        `${props.name}-modal`,
        `size-${props.size}`,
        {
          'closable': props.allowClose,
          'with-footer': props.footer
        }
      ]"
      :style="customStyle"
      :aria-describedby="`${props.name}-${props.ariaDescribedBy}`"
      :aria-labelledby="`${props.name}-${props.ariaLabelledBy}`"
    >
      <div
        class="modal-header-wrapper"
        :class="{ bordered: props.borderedHeader }"
      >
        <div class="modal-header">
          <div
            :id="`${props.name}-modal-header-title`"
            class="modal-header-title"
          >
            <slot name="title" />
          </div>
          <button
            v-if="props.allowClose"
            class="modal-header-close-button"
            type="button"
            :disabled="props.closeButtonDisabled"
            :title="t('common.Close')"
            @click="handleClose"
          >
            <AlgIcon
              name="cancel"
              size="m"
            />
          </button>
        </div>
        <div class="modal-subheader">
          <slot name="subheader" />
        </div>
      </div>
      <div
        :id="`${props.name}-modal-content`"
        class="modal-content"
        :class="{'no-padding': props.noPadding}"
      >
        <slot name="content" />
      </div>
      <div
        v-if="props.footer"
        class="modal-footer"
        :class="{ bordered: props.borderedFooter }"
      >
        <slot name="footer">
          <template v-if="props.allowCancel">
            <div class="centered-button">
              <AlgButton
                v-if="props.cancelButtonDisplayed"
                :label="props.cancelButtonText ?? t('common.Cancel')"
                :variant="props.cancelButtonVariant"
                :disabled="props.cancelButtonDisabled"
                @click="handleCancel"
              />
              <AlgButton
                :type="props.confirmButtonType"
                :label="props.confirmButtonText ?? t('common.Validate')"
                :variant="props.confirmButtonVariant"
                :disabled="props.confirmButtonDisabled"
                :loading="props.confirmButtonLoading"
                @click="handleConfirm"
              />
            </div>
          </template>
          <template v-else>
            <AlgButton
              :label="props.confirmButtonText ?? t('common.Close')"
              :variant="props.confirmButtonVariant"
              :disabled="props.confirmButtonDisabled"
              :loading="props.confirmButtonLoading"
              @click="handleConfirm"
            />
          </template>
        </slot>
      </div>
    </dialog>
  </Teleport>
</template>

<style scoped>
.modal {
  --modal-header-height: 75px;
  --modal-footer-height: 75px;

  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 1px solid var(--alg-color-surface-border);
  border-radius: var(--alg-effect-radius-l);
  background-color: var(--alg-color-surface-primary);

  &::backdrop {
    background-color: rgb(0 0 0 / 50%);
  }

  &.size-auto {
    width: auto;
    height: auto;
    border-radius: var(--alg-effect-radius-l);
    box-shadow: var(--alg-effect-shadow-m);
  }

  /* Classic modals */
  &.size-xs {
    @media screen and (width >= 430px) {
      width: 430px;
      height: 280px;
      border-radius: var(--alg-effect-radius-l);
      box-shadow: var(--alg-effect-shadow-m);
    }
  }

  &.size-s {
    @media screen and (width >= 430px) {
      width: 430px;
      height: 380px;
      border-radius: var(--alg-effect-radius-l);
      box-shadow: var(--alg-effect-shadow-m);
    }
  }

  &.size-m {
    @media screen and (width >= 430px) {
      width: 430px;
      height: 500px;
      border-radius: var(--alg-effect-radius-l);
      box-shadow: var(--alg-effect-shadow-m);
    }
  }

  &.size-l {
    @media screen and (width >= 430px) {
      width: 430px;
      height: 620px;
      border-radius: var(--alg-effect-radius-l);
      box-shadow: var(--alg-effect-shadow-m);
    }
  }

  &.size-xl {
    @media screen and (width >= 720px) {
      width: 720px;
      height: 90%;
      min-height: 620px;
      border-radius: var(--alg-effect-radius-l);
      box-shadow: var(--alg-effect-shadow-m);
    }
  }

  /* Double column modals */
  &.size-double-xs {
    @media screen and (width >= 920px) {
      width: 920px;
      height: 280px;
      border-radius: var(--alg-effect-radius-l);
      box-shadow: var(--alg-effect-shadow-m);
    }
  }

  &.size-double-s {
    @media screen and (width >= 920px) {
      width: 920px;
      height: 380px;
      border-radius: var(--alg-effect-radius-l);
      box-shadow: var(--alg-effect-shadow-m);
    }
  }

  &.size-double-m {
    @media screen and (width >= 920px) {
      width: 920px;
      height: 500px;
      border-radius: var(--alg-effect-radius-l);
      box-shadow: var(--alg-effect-shadow-m);
    }
  }

  &.size-double-l {
    @media screen and (width >= 920px) {
      width: 920px;
      height: 620px;
      border-radius: var(--alg-effect-radius-l);
      box-shadow: var(--alg-effect-shadow-m);
    }
  }

  /* Specific modals */
  &.size-full {
    @media screen and (width >= 1250px) {
      width: 1250px;
      height: 700px;
      border-radius: var(--alg-effect-radius-l);
      box-shadow: var(--alg-effect-shadow-m);
    }
  }

  .modal-header-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;

    &.bordered {
      border-bottom: 1px solid var(--alg-color-surface-border);
    }

    .modal-header {
      display: flex;
      width: 100%;
      height: var(--modal-header-height);
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      padding: var(--alg-spacing-m);
      gap: var(--alg-spacing-m);

      .modal-header-title {
        display: flex;
        width: calc(100% - 24px - var(--alg-spacing-m));
        align-items: center;
        justify-content: flex-start;
        color: var(--alg-color-text-primary);
        font-size: var(--alg-font-size-xl);
        font-weight: var(--alg-font-weight-bold);
        text-align: left;
      }

      .modal-header-close-button {
        width: 24px;
        height: 24px;
        padding: 0;
        border-radius: 50%;
        color: var(--alg-color-text-secondary);
        transition: color 150ms ease-in-out;

        &:hover {
          color: var(--alg-color-text-primary);
        }

        &[disabled] {
          color: var(--alg-color-text-light);
          cursor: not-allowed;
        }
      }
    }

    .modal-subheader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 var(--alg-spacing-m);
    }
  }

  .modal-content {
    display: flex;
    max-height: calc(100% - var(--modal-header-height));
    flex: 1 1 auto;
    padding: var(--alg-spacing-m);
    color: var(--alg-color-text-primary);
    overflow-y: auto;

    &.no-padding {
      padding: 0;
    }

    @media screen and (width >= 720px) {
      padding: var(--alg-spacing-l);
    }
  }

  .modal-footer {
    display: flex;
    height: var(--modal-footer-height);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: var(--alg-spacing-m);
    gap: var(--alg-spacing-s);

    .centered-button {
      display: flex;
      margin: 0 auto;
      gap: var(--alg-spacing-s);
    }

    &.bordered {
      border-top: 1px solid var(--alg-color-surface-border);
    }
  }

  &.closable {
    .modal-header-wrapper {
      .modal-header-title {
        width: calc(100% - var(--alg-spacing-m));
      }
    }
  }

  &.with-footer {
    max-height: calc(100% - var(--modal-header-height) - var(--modal-footer-height));
  }

  @media screen and (width <= 500px) {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    box-sizing: border-box;
    border-radius: 0 !important;
    margin: 0;
    box-shadow: none;

    &.with-footer {
      max-height: inherit;
    }
  }
}
</style>
