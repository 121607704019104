<script lang="ts" setup>
  import { storeToRefs } from 'pinia'

  import { AlgIllustration, AlgSpinner } from '@algorh/ui'

  import { useAppStore } from '@/plugins'

  type Props = {
    readonly size?: number
  }

  const props = withDefaults(defineProps<Props>(), {
    size: 128,
  })

  const appStore = useAppStore()

  const { appLogo } = storeToRefs(appStore)
</script>

<template>
  <div class="loader">
    <AlgSpinner
      :style="{
        width: `${props.size}px`,
        height: `${props.size}px`,
      }"
    />
    <div class="logo">
      <AlgIllustration
        prefix="brand"
        :name="appLogo"
        :size="props.size * 0.6"
      />
    </div>
  </div>
</template>

<style scoped>
  .loader {
    position: relative;
    width: fit-content;
    height: fit-content;
    color: var(--alg-color-icon-highlight);

    .logo {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--alg-color-surface-primary);
      inset: var(--alg-spacing-s);
    }
  }
</style>
