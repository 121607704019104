<script setup lang="ts" generic="T extends Nullable<DateString>">
  import { DateString, Nullable } from '@algorh/shared'

  import { AlgCalendar } from '../../calendars'
  import { AlgPopper } from '../popper'

  interface Props {
    readonly minValue?: DateString
    readonly maxValue?: DateString
    readonly allowedDays?: number[] // 1-7
    readonly mode?: 'day' | 'week'
    readonly closeOnSelect?: boolean
    readonly dailyCounts?: Nullable<Record<DateString, {
      absence: boolean
      day_off: boolean
    }>>
  }

  const props = withDefaults(defineProps<Props>(), {
    allowedDays: () => [0, 1, 2, 3, 4, 5, 6],
    mode: 'day',
    closeOnSelect: true,
  })

  const model = defineModel<T>({ required: true })

  function handleModelUpdate(value: T) {
    model.value = value
  }
</script>

<template>
  <AlgPopper placement="bottom-start">
    <template #reference="{ toggle, isOpen }">
      <slot
        name="reference"
        v-bind="{ toggle, isOpen }"
      />
    </template>
    <template #content="{ close }">
      <AlgCalendar
        v-model="model"
        display-header
        :close-callback="close"
        :min-value="props.minValue"
        :max-value="props.maxValue"
        :allowed-days="props.allowedDays"
        :mode="props.mode"
        :close-on-select="props.closeOnSelect"
        :daily-counts="props.dailyCounts"
        @update:model-value="handleModelUpdate"
      />
    </template>
  </AlgPopper>
</template>
