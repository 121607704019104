import { minuteToStringTime } from '@algorh/shared'

import { i18n } from '@/plugins'

import { HumanizeAmbition } from './HumanizeAmbition.type'

const { t } = i18n.global

export const humanizeAmbition = {
  wrapWithDot(value: string, hasDots = true): string {
    if (!hasDots) {
      return value
    }
    return `<span class="has-dot">${value}</span>`
  },
  humanizeMesh(ambition: HumanizeAmbition, hasDots = true): string {
    const mesh = ambition.config?.mesh
      ? this.wrapWithDot(t(`ambitions.parts.${ambition.config?.mesh}`), hasDots)
      : ''

    if (ambition.config?.from_nth === null) {
      return ''
    }
    return t('ambitions.templates.mesh', {
      from_nth:
        ambition.config?.from_nth !== null
          ? this.wrapWithDot(ambition.config?.from_nth?.toString() ?? '0', hasDots)
          : '',
      mesh,
    }, 0)
  },
  humanizeTimingFilters(ambition: HumanizeAmbition, hasDots = true): string {
    if (ambition.config?.timing === null || ambition.config?.timing === 'any_time') {
      return this.wrapWithDot(t('ambitions.parts.any_time'), hasDots) + ' '
    }
    const hasOther = ['just_before', 'just_after'].includes(ambition.config?.timing ?? '')
    const other = hasOther ? this.wrapWithDot(ambition.other ?? '', hasDots) : ''
    return this.wrapWithDot(t(`ambitions.parts.${ambition.config?.timing}`), hasDots) + ' ' + other
  },
  humanizeDuration(ambition: HumanizeAmbition): string {
    return ambition.config?.duration === 0
      ? t('ambitions.parts.any_duration') + ' '
      : minuteToStringTime(ambition.config?.duration ?? 0) + ' '
  },
  ambitionOverridedHtml(ambition: HumanizeAmbition): string {
    return (
      t(`ambitions.templates.template_${ambition.ambition_template_id}`, {
        action: t(`ambitions.parts.${ambition.action}`),
        subject: ambition.subject ?? t('ambitions.parts.any_activity'),
        duration: this.humanizeDuration(ambition),
        nth_value: this.humanizeMesh(ambition, false),
        timing: this.humanizeTimingFilters(ambition, false),
      }, 0)
      + ' '
      + this.wrapWithDot(t('ambitions.parts.with a score of') + ' ' + ambition.score)
    )
  },
  ambitionHtml(ambition: HumanizeAmbition): string {
    return (
      t(`ambitions.templates.template_${ambition.ambition_template_id}`, {
        action: this.wrapWithDot(t(`ambitions.parts.${ambition.action}`)),
        subject: this.wrapWithDot(ambition.subject ?? t('ambitions.parts.any_activity')),
        duration: this.wrapWithDot(this.humanizeDuration(ambition)),
        nth_value: this.humanizeMesh(ambition),
        timing: this.humanizeTimingFilters(ambition),
      }, 0)
      + ' '
      + this.wrapWithDot(t('ambitions.parts.with a score of') + ' ' + ambition.score)
    )
  },
}
