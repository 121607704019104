<script setup lang="ts" generic="T extends Nullable<DateString>">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { DateString, dayjs, DTF, Nullable } from '@algorh/shared'

  import { AlgDot } from '#/components/dot'

  import { AlgIconButton } from '../../icon-button'

  interface Props {
    readonly minValue?: DateString
    readonly maxValue?: DateString
    readonly allowedDays?: number[] // 1-7
    readonly mode?: 'day' | 'week'
    readonly closeOnSelect?: boolean
    readonly displayHeader?: boolean
    readonly readonly?: boolean
    readonly density?: 's' | 'm' | 'l'
    readonly dailyCounts?: Nullable<Record<DateString, {
      absence: boolean
      day_off: boolean
    }>>
    readonly closeCallback?: () => void
  }

  const props = withDefaults(defineProps<Props>(), {
    allowedDays: () => [0, 1, 2, 3, 4, 5, 6],
    mode: 'day',
    closeOnSelect: true,
    closeCallback: () => null,
    density: 'l',
  })

  const emit = defineEmits<{
    (e: 'day-click', d: T): void
  }>()

  const model = defineModel<T>({ required: true })

  const date = computed<DateString>({
    get: () => model.value ?? dayjs().format(DTF.DATE),
    set: (value) => {
      model.value = value as T
    },
  })

  // Composables
  const { t } = useI18n()

  // Refs

  // Computed
  const weekDays = dayjs.weekdays(true).map((day: string) => day.substring(0, 1))

  const weeks = computed(() => getMondaysInMonth(date.value))

  const startOf = computed(() => dayjs(date.value, DTF.DATE).startOf('month').weekday(0))

  const days = computed(() => Array.from({ length: 7 * weeks.value.length }, (_, i) => startOf.value.add(i, 'day').format(DTF.DATE)))

  const currentMonthWithYear = computed(() => dayjs(date.value).format(DTF.DATE_MONTH_YEAR))

  const currentWeekNumber = computed(() => dayjs(date.value).isoWeek())

  const convertedAllowedDays = computed(() => props.allowedDays.map((day: number) => day === 7 ? 0 : day))

  // Methods
  function getMondaysInMonth(date: DateString) {
    const firstMonday = dayjs(date).startOf('month').startOf('week')
    return Array.from({ length: 6 }, (_, i) => firstMonday?.add(7 * i, 'day').format(DTF.DATE))
  }

  function computeDays(index: number) {
    const computedDays = days.value.map((day) => ({
      day,
      formattedDay: dayjs(day).format(DTF.DAY_NUMBER),
      isToday: dayjs(day).isSame(dayjs(), 'day'),
      isCurrentMonth: dayjs(day).isSame(dayjs(date.value, DTF.DATE), 'month'),
      isDayOff: props.dailyCounts ? Object.entries(props.dailyCounts).some(([key, value]) => dayjs(day).isSame(dayjs(key), 'day') && value.day_off) : false,
      isAbsence: props.dailyCounts ? Object.entries(props.dailyCounts).some(([key, value]) => dayjs(day).isSame(dayjs(key), 'day') && value.absence) : false,
      disabled: (props.minValue && dayjs(day).isBefore(dayjs(props.minValue)))
        || (props.maxValue && dayjs(day).isAfter(dayjs(props.maxValue)))
        || !convertedAllowedDays.value.includes(dayjs(day).day()),
    }))

    return computedDays.slice(index * 7, (index + 1) * 7)
  }

  function handlePreviousMonth() {
    model.value = dayjs(date.value, DTF.DATE).subtract(1, 'month').format(DTF.DATE) as T
  }

  function handleNextMonth() {
    model.value = dayjs(date.value, DTF.DATE).add(1, 'month').format(DTF.DATE) as T
  }

  function handleToday() {
    model.value = dayjs().format(DTF.DATE) as T
  }

  function handleWeekClick(monday: DateString, close: () => void) {
    model.value = monday as T
    if (props.closeOnSelect) {
      close()
    }
  }

  function handleDayClick(day: DateString, close: () => void) {
    emit('day-click', day as T)
    if (!props.readonly) {
      model.value = day as T
    }
    if (props.closeOnSelect) {
      close()
    }
  }
</script>

<template>
  <div class="calendar">
    <div
      v-if="props.displayHeader"
      class="calendar-header"
    >
      <div class="calendar-header-month-switcher">
        <AlgIconButton
          id="calendar-header-prev"
          class="calendar-header-prev"
          variant="transparent"
          icon="chevron-left"
          :title="t('common.Previous')"
          @click="handlePreviousMonth"
        />
        <span class="calendar-header-title">
          {{ currentMonthWithYear }}
        </span>
        <AlgIconButton
          id="calendar-header-next"
          class="calendar-header-next"
          variant="transparent"
          icon="chevron-right"
          :title="t('common.Next')"
          @click="handleNextMonth"
        />
      </div>
      <AlgIconButton
        id="calendar-header-today"
        class="calendar-header-today"
        variant="transparent"
        icon="today"
        :title="t('datetime.Today')"
        @click="handleToday"
      />
    </div>
    <div class="calendar-month">
      <div
        class="calendar-week calendar-week-header"
        :class="`density-${props.density}`"
      >
        <span
          class="calendar-cell calendar-week-number"
          :class="`density-${props.density}`"
        >
          {{ t('datetime.Wk') }}
        </span>
        <span
          v-for="(day, i) in weekDays"
          :key="i"
          class="calendar-cell calendar-day"
          :class="`density-${props.density}`"
        >
          {{ day }}
        </span>
      </div>
      <template v-if="props.mode === 'week'">
        <button
          v-for="(week, i) in weeks"
          :key="i"
          type="button"
          class="calendar-week calendar-week-body calendar-button"
          :class="[
            {
              'calendar-week-selected': dayjs(week, DTF.DATE).isoWeek() === currentWeekNumber && dayjs(week).isoWeekYear() === dayjs(model).isoWeekYear()
            },
            `density-${props.density}`
          ]"
          :disabled="
            (props.minValue && dayjs(week).isBefore(dayjs(props.minValue)))
              || (props.maxValue && dayjs(week).isAfter(dayjs(props.maxValue)))"
          @click="() => handleWeekClick(week, props.closeCallback)"
        >
          <span
            class="calendar-cell calendar-week-number"
            :class="`density-${props.density}`"
          >
            {{ dayjs(week).isoWeek() }}
          </span>
          <span
            v-for="(day, k) in computeDays(i)"
            :key="k"
            :class="[
              {
                'is-today': day.isToday,
                'is-previous-or-next-month': !day.isCurrentMonth,
              },
              `density-${props.density}`
            ]"
            class="calendar-cell calendar-day"
          >
            {{ day.formattedDay }}
          </span>
        </button>
      </template>
      <template v-if="props.mode === 'day'">
        <span
          v-for="(week, i) in weeks"
          :key="i"
          class="calendar-week calendar-week-body"
          :class="`density-${props.density}`"
        >
          <span
            class="calendar-cell calendar-week-number"
            :class="`density-${props.density}`"
          >
            {{ dayjs(week).isoWeek() }}
          </span>
          <button
            v-for="(day, k) in computeDays(i)"
            :key="k"
            type="button"
            class="calendar-cell calendar-day calendar-button"
            :class="[
              {
                'is-today': day.isToday,
                'is-previous-or-next-month': !day.isCurrentMonth,
                'calendar-day-selected': !props.readonly && dayjs(day.day).isSame(dayjs(model), 'day'),
              },
              `density-${props.density}`
            ]"
            :disabled="day.disabled"
            @click="() => handleDayClick(day.day, props.closeCallback)"
          >
            <slot
              :name="`day(${day.day})`"
              :number="day.formattedDay"
              :is-today="day.isToday"
              :is-current-month="day.isCurrentMonth"
            >
              {{ day.formattedDay }}
            </slot>
            <div
              v-if="day.isDayOff || day.isAbsence"
              class="dot-container"
            >
              <AlgDot
                v-if="day.isDayOff"
                size="xxs"
                color="var(--alg-color-pink-100)"
              />
              <AlgDot
                v-if="day.isAbsence"
                size="xxs"
                color="var(--alg-color-cyan-100)"
              />
            </div>
          </button>
        </span>
      </template>
    </div>
    <div
      v-if="props.dailyCounts && Object.values(props.dailyCounts).some(value => value.day_off || value.absence)"
      class="calendar-footer"
    >
      <ul class="legend">
        <li class="item">
          <AlgDot
            size="xs"
            color="var(--alg-color-pink-100)"
            :label="t('scheduling.Day off')"
          />
        </li>
        <li class="item">
          <AlgDot
            size="xs"
            color="var(--alg-color-cyan-100)"
            :label="t('absences.Absence')"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.calendar {
  display: flex;
  flex:  0 0 auto;
  flex-direction: column;
  padding: var(--alg-spacing-s) var(--alg-spacing-m) var(--alg-spacing-m);
  border-radius: var(--alg-effect-radius-s);
  background-color: var(--alg-color-surface-primary);
  box-shadow: var(--alg-effect-shadow-l);

  .calendar-header {
    display: flex;
    height: var(--alg-spacing-xl);
    justify-content: space-between;
    margin-bottom: var(--alg-spacing-s);

    .calendar-header-month-switcher {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: var(--alg-spacing-s);

      .calendar-header-title {
        width: 128px;
        color: var(--alg-color-text-primary);
        font-size: var(--alg-font-size-m);
        font-weight: var(--alg-font-weight-bold);
        text-align: center;
        text-transform: capitalize;
      }
    }

    .calendar-header-prev,
    .calendar-header-next,
    .calendar-header-today {
      display: flex;
      width: var(--alg-spacing-xl);
      height: var(--alg-spacing-xl);
      align-items: center;
      justify-content: center;
      padding: 0;
      border-radius: 50%;
      color: var(--alg-color-icon-secondary);
      transition:
        background-color 150ms ease-in-out,
        color 150ms ease-in-out;

      &:hover {
        background-color: var(--alg-color-surface-background);
        color: var(--alg-color-icon-primary);
      }
    }
  }

  .calendar-month {
    display: flex;
    height: 100%;
    flex: 0 0 auto;
    flex-direction: column;
    user-select: none;

    .calendar-week {
      display: grid;
      overflow: hidden;
      padding: 0;
      border-radius: var(--alg-spacing-xl);
      margin-bottom: var(--alg-spacing-xs);
      gap: 2px;
      grid-template-columns: repeat(8, 1fr);

      &.density-s {
        margin-bottom: 0;
      }

      .calendar-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--alg-color-text-primary);
        font-weight: var(--alg-font-weight-bold);

        &.is-previous-or-next-month {
          color: var(--alg-color-text-secondary);
          font-weight: var(--alg-font-weight-light);
        }

        &.density-s {
          width: 1.5rem;
          height: 1.5rem;
          font-size: var(--alg-font-size-xxs);
        }

        &.density-m {
          width: 1.75rem;
          height: 1.75rem;
          font-size: var(--alg-font-size-xs);
        }

        &.density-l {
          width: 2rem;
          height: 2rem;
          font-size: var(--alg-font-size-s);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.calendar-week-header {
        .calendar-day {
          color: var(--alg-color-text-secondary);
          font-weight: var(--alg-font-weight-light);
          text-transform: capitalize;

          &.density-s {
            font-size: var(--alg-font-size-xs);
          }

          &.density-m {
            font-size: var(--alg-font-size-s);
          }

          &.density-l {
            font-size: var(--alg-font-size-m);
          }
        }
      }

      &.calendar-week-body {
        transition: background-color 150ms ease-in-out;

        .calendar-cell {
          border-radius: 50%;
        }

        .calendar-week-number {
          color: var(--alg-color-text-highlight);
        }
      }

      .calendar-button {
        position: relative;
        padding: 0;

        &.is-today {
          background-color: var(--alg-color-button-primary-light);
        }

        .dot-container {
          position: absolute;
          bottom: var(--alg-spacing-xs);
          display: flex;
          gap: var(--alg-spacing-xs);
        }

        &.calendar-week-selected {
          background-color: var(--alg-color-button-primary-light-hover);

          .calendar-week-number {
            background-color: var(--alg-color-button-primary);
            color: var(--alg-color-text-on-color);
          }
        }

        &.calendar-day-selected,
        &.calendar-day-selected:hover {
          background-color: var(--alg-color-button-primary);
          color: var(--alg-color-text-on-color);
        }

        &:hover {
          background-color: var(--alg-color-button-primary-light-hover);
        }

        &[disabled] {
          color: var(--alg-color-text-secondary);

          .calendar-cell {
            color: var(--alg-color-text-secondary);
          }

          &:hover {
            background-color: var(--alg-color-button-primary-light-disabled);
          }
        }
      }
    }
  }

  .calendar-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--alg-spacing-s);

    .legend {
      display: flex;
      padding-right: var(--alg-spacing-s);
      gap: var(--alg-spacing-s);

      .item {
        display: flex;
        gap: var(--alg-spacing-xs);
      }
    }
  }
}
</style>
