<script setup lang="ts">
  import { useSlots } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { AlgButton, AlgIconButton, AlgNoData, ModalSize } from '@algorh/ui'

  import ResetModal from '@/components/modals/ResetModal.vue'
  import WarningModal from '@/components/modals/WarningModal.vue'

  import Loader from './Loader.vue'

  type Props = {
    readonly hasNoData?: boolean
    readonly isLoading?: boolean
    readonly noDataTitle?: string
    readonly noDataSubtitle?: string
    readonly isEditorOpened?: boolean
    readonly isResetModalOpened?: boolean
    readonly resetModalSize?: ModalSize
    readonly isWarningModalOpened?: boolean
    readonly warningModalSize?: ModalSize
    readonly canReset?: boolean
    readonly canSave?: boolean
    readonly noPadding?: boolean
    readonly confirmButtonLoading?: boolean
    readonly resetButtonLoading?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    hasNoData: false,
    isEditorOpened: false,
    isResetModalOpened: false,
    resetModalSize: 's',
    isWarningModalOpened: false,
    warningModalSize: 's',
    canReset: false,
    canSave: true,
    confirmButtonLoading: false,
    resetButtonLoading: false,
  })

  const emit = defineEmits<{
    (e: 'close-editor'): void
    (e: 'open-reset-modal'): void
    (e: 'close-reset-modal'): void
    (e: 'close-warning-modal'): void
    (e: 'save-override'): void
    (e: 'apply-warning-modal'): void
    (e: 'apply-reset'): void
  }>()

  const { t } = useI18n()

  const slots = useSlots()

  function handleCloseEditor() {
    emit('close-editor')
  }

  function handleOpenResetModal() {
    emit('open-reset-modal')
  }

  function handleCloseResetModal() {
    emit('close-reset-modal')
  }

  function handleCloseWarningModal() {
    emit('close-warning-modal')
  }

  function handleSave() {
    emit('save-override')
  }

  function handleConfirm() {
    emit('apply-warning-modal')
  }

  function handleReset() {
    emit('apply-reset')
  }
</script>

<template>
  <div class="override-template">
    <div class="template-container">
      <div class="container-main">
        <slot name="menu" />
        <div class="list-container">
          <div
            class="list-content"
            :class="{ 'no-padding': props.noPadding }"
          >
            <AlgNoData
              v-if="props.hasNoData"
              class="no-data"
              illustration="not-found"
              :title="props.noDataTitle ?? t('common.No data available')"
              :subtitle="props.noDataSubtitle && props.noDataSubtitle"
            />

            <div
              v-else-if="props.isLoading"
              class="spinner"
            >
              <Loader class="loader" />
            </div>
            <slot
              v-else
              name="content"
            />
          </div>
        </div>
      </div>
      <div
        v-if="props.isEditorOpened"
        class="editor"
      >
        <div class="editor-header">
          <div class="editor-header-top">
            <slot name="editor-header" />
            <AlgIconButton
              id="close-editor-button"
              icon="cancel"
              variant="transparent"
              icon-color="var(--alg-color-icon-secondary)"
              size="l"
              :title="t('common.Close')"
              @click="handleCloseEditor"
            />
          </div>
          <slot name="editor-sub-header" />
        </div>
        <div class="editor-body">
          <div class="editor-body-synthese">
            <slot name="editor-synthese" />
            <div class="reset-wrapper">
              <slot name="editor-synthese-action" />
              <AlgButton
                v-if="props.canReset"
                class="reset-button"
                variant="secondary"
                icon-start="restart-alt"
                :label="t('common.Reset')"
                @click="handleOpenResetModal"
              />
            </div>
          </div>
          <slot name="editor-content" />
        </div>
        <div class="editor-footer">
          <slot name="footer" />
          <AlgButton
            :label="t('common.Validate')"
            :disabled="!props.canSave"
            :loading="props.confirmButtonLoading"
            @click="handleSave"
          />
        </div>
      </div>
      <ResetModal
        v-if="props.isResetModalOpened"
        :size="props.resetModalSize"
        :confirm-button-loading="props.resetButtonLoading"
        @close="handleCloseResetModal"
        @cancel="handleCloseResetModal"
        @confirm="handleReset"
      >
        <div class="reset-content">
          <strong>
            {{ t('common.Warning') }}
          </strong>
          <div class="reset-message">
            <slot name="reset-message" />
          </div>
          <div class="editor-body-synthese">
            <div class="reset-synthese">
              <slot name="reset-content" />
            </div>
          </div>
        </div>
      </ResetModal>
      <WarningModal
        v-if="props.isWarningModalOpened"
        id="warning-modal"
        :size="props.warningModalSize"
        @confirm="handleConfirm"
        @cancel="handleCloseWarningModal"
        @close="handleCloseWarningModal"
      >
        <slot name="warning-message" />
        <div
          v-if="slots['warning-content']"
          class="editor-body-synthese"
        >
          <slot name="warning-content" />
        </div>
      </WarningModal>
    </div>
  </div>
</template>

<style scoped>
  .override-template {
    height: 100%;

    .template-container {
      position: relative;
      display: flex;
      height: 100%;
      justify-content: center;
      margin: 0 auto;

      .container-main {
        display: flex;
        max-width: 100%;
        flex: 1;
        flex-direction: column;

        .list-container {
          flex: 1 1 auto;
          border-radius: var(--alg-effect-radius-m);
          background-color: var(--alg-color-surface-primary);
          overflow-x: auto;

          .list-content {
            position: relative;
            display: flex;
            height: 100%;
            box-sizing: border-box;
            flex-direction: column;
            padding: 0 20px 20px;

            &.no-padding {
              padding: 0 0 20px;
            }

            .no-data {
              height: 100%;
            }

            .spinner {
              display: flex;
              flex: 1 1 100%;
              align-items: center;
              justify-content: center;
              padding: 50px 0;
              inset: 0;
            }
          }

          .list-content section {
            margin: 40px 0;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }

    }

    .editor {
      position: fixed;
      z-index: 21;
      display: flex;
      width: 100%;
      flex-direction: column;
      background-color: var(--alg-color-surface-primary);
      inset: 120px 0 0;

      @media (width >= 720px) {
        position: relative;
        z-index: 10;
        top: inherit;
        top: 0;
        right: 20px;
        bottom: 0;
        left: inherit;
        display: flex;
        min-width: 450px;
        max-width: 450px;
        min-height: 400px;
        max-height: calc(100vh - 100px);
        flex-direction: column;
        border: 1px solid var(--alg-color-surface-border);
        border-radius: var(--alg-effect-radius-m);
        margin-left: 20px;
        background-color: var(--alg-color-surface-primary);
        box-shadow: var(--alg-effect-shadow-m);
      }

      @media (width >= 1150px) {
        position: sticky;
        top: 0;
        right: auto;
        bottom: 0;
        border: none;
        border-radius: var(--alg-effect-radius-m);
        box-shadow: none;
      }
    }

    .editor-header {
      position: relative;
      z-index: 1;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 25px;
      border-bottom: 1px solid var(--alg-color-surface-border);
      color: var(--alg-color-text-primary);
      font-size: var(--alg-font-size-l);
      font-weight: var(--alg-font-weight-bold);
      line-height: normal;

      .editor-header-top {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
    }

    .editor-body {
      flex: 1;
      padding: 20px;
      overflow-y: auto;
    }

    .reset-wrapper {
      position: relative;
      display: flex;
      justify-content: center;

      .reset-button {
        margin-bottom: 20px;
      }
    }

    .editor-footer {
      position: sticky;
      bottom: 0;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0;
      border-top: 1px solid var(--alg-color-surface-border);

      button {
        margin: auto;
      }
    }
  }

  .reset-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;
    gap: var(--alg-spacing-m);
    text-align: center;
  }

  .editor-body-synthese {
    display: flex;
    min-width: 50%;
    flex-direction: column;
    border: 1px solid var(--alg-color-surface-border);
    border-radius: var(--alg-effect-radius-m);
    margin-bottom: 20px;
    background-color: var(--alg-color-surface-primary);
    color: var(--alg-color-text-primary);
    font-size: var(--alg-font-size-m);
    font-weight: var(--alg-font-weight-regular);
    text-align: left;
    text-align: center;

    .reset-synthese {
      padding: 10px;
      line-height: var(--alg-font-line-height);
    }
  }
</style>
