<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { FloatingPlacement, Nullable } from '@algorh/shared'

  import { ButtonSize, ButtonVariant, IconButtonSize, IconButtonVariant, IconName } from '#/types'

  import { AlgButton } from '../button'
  import { AlgDropdown, DropdownMenuOption } from '../dropdown'
  import { AlgIconButton } from '../icon-button'

  interface Props {
    id: string
    label?: string
    variant?: ButtonVariant
    size?: ButtonSize
    disabled?: boolean
    inactive?: boolean
    block?: boolean
    loading?: boolean
    iconStart?: IconName
    iconEnd?: IconName
    dropdownIcon?: IconName
    dropdownTooltipLabel?: string
    dropdownDisabled?: boolean
    dropdownInactive?: boolean
    dropdownPlacement?: FloatingPlacement
    options: DropdownMenuOption[]
  }

  const props = withDefaults(defineProps<Props>(), {
    variant: 'primary',
    size: 'm',
    block: false,
    disabled: false,
    inactive: false,
    loading: false,
    dropdownIcon: 'expand-more',
    dropdownDisabled: false,
    dropdownInactive: false,
  })

  const emit = defineEmits<{
    (e: 'click'): void
    (e: 'select', value: Nullable<string | number>): void
  }>()

  const { t } = useI18n()

  function handleClick() {
    emit('click')
  }

  function handleSelectOption(value: Nullable<string | number>) {
    emit('select', value)
  }
</script>

<template>
  <div class="dropdown-button-wrapper">
    <AlgButton
      class="default-button"
      :class="`variant-${props.variant}`"
      :label="props.label"
      :variant="props.variant"
      :size="props.size"
      :disabled="props.disabled"
      :inactive="props.inactive"
      :loading="props.loading"
      :icon-start="props.iconStart"
      :icon-end="props.iconEnd"
      @click="handleClick"
    />
    <AlgDropdown
      :id="`${props.id}-dropdown`"
      :options="props.options"
      :placement="props.dropdownPlacement"
      @update:model-value="handleSelectOption"
    >
      <template #reference="{ toggle, isOpen }">
        <AlgIconButton
          :id="`${props.id}-dropdown-reference`"
          class="dropdown-button"
          :variant="props.variant as IconButtonVariant"
          :size="props.size as IconButtonSize"
          :disabled="props.dropdownDisabled"
          :inactive="props.dropdownInactive"
          :icon="props.dropdownIcon"
          :icon-rotate="isOpen ? 180 : 0"
          :tooltip="!!props.dropdownTooltipLabel"
          :tooltip-label="props.dropdownTooltipLabel"
          :title="isOpen ? t('common.Close menu') : t('common.Open menu')"
          @click="toggle"
        />
      </template>
    </AlgDropdown>
  </div>
</template>

<style scoped>
.dropdown-button-wrapper {
  display: flex;

  .default-button {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &.variant-primary {
      border-right-color: var(--alg-color-surface-background);
    }
  }

  .dropdown-button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
</style>
