<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { DateString, dayjs, DTF, Nullable, useIsMobile } from '@algorh/shared'

  import { AlgErrors } from '../../../feedback'
  import { AlgIcon } from '../../../media'
  import { AlgCalendarPopper } from '../../../popover'
  import { AlgLabel } from '../../label'
  import { InputSize, InputVariant } from '../Input.type'

  type Props = {
    readonly id: string
    readonly label?: string
    readonly sublabel?: string
    readonly noneLabel?: string
    readonly size?: InputSize
    readonly inline?: boolean
    readonly required?: boolean
    readonly disabled?: boolean
    readonly readonly?: boolean
    readonly searchable?: boolean
    readonly errored?: boolean
    readonly errors?: string[]
    readonly minValue?: DateString
    readonly maxValue?: DateString
    readonly closeOnSelect?: boolean
    readonly variant?: InputVariant
  }

  defineOptions({
    name: 'AlgWeekInput',
  })

  const props = withDefaults(defineProps<Props>(), {
    size: 'm',
    inline: false,
    required: false,
    disabled: false,
    readonly: false,
    searchable: false,
    errored: false,
    closeOnSelect: true,
    variant: 'primary',
  })

  const model = defineModel<Nullable<DateString>>({ required: true })

  // Composables
  const { t } = useI18n()

  const isMobile = useIsMobile()

  // Refs
  const inputRef = ref<Nullable<HTMLInputElement>>(null)

  // Computed
  const hasErrors = computed(() => props.errored || (props.errors && props.errors.length > 0))

  const currentWeekNumber = computed(() => dayjs(model.value).isoWeek())

  const computedLabel = computed(() => {
    const week = currentWeekNumber.value

    const year = dayjs(model.value).endOf('week').format(DTF.YEAR)

    return model.value
      ? t('datetime.Wk {week} {year}', { week, year })
      : t('common.Undefined (m)')
  })

  const week = computed({
    get: () => dayjs(model.value).format('YYYY-[W]WW'),
    set: (value: string) => {
      model.value = value === '' ? null : dayjs().week(Number(value.slice(6))).format(DTF.DATE)
    },
  })

  // Methods
  function handleClear() {
    model.value = null
  }

  function handleClick(toggle: () => void) {
    try {
      if (isMobile.value && inputRef.value?.showPicker) {
        inputRef.value.showPicker()
      } else {
        toggle()
      }
    } catch {
      toggle()
    }
  }
</script>

<template>
  <div
    class="field-wrapper"
    :class="{ inline: props.inline}"
  >
    <AlgLabel
      v-if="props.label"
      :label="props.label"
      :sublabel="props.sublabel"
      :html-for="props.id"
      :inline="props.inline"
      :input-size="props.size"
      :required="props.required"
      :errored="hasErrors"
    />
    <div class="field-content">
      <AlgCalendarPopper
        v-model="model"
        mode="week"
        :close-on-select="props.closeOnSelect"
        :min-value="props.minValue"
        :max-value="props.maxValue"
      >
        <template #reference="{ toggle, isOpen }">
          <div class="input-wrapper">
            <label for="week-input">
              <input
                id="week-input"
                ref="inputRef"
                v-model="week"
                class="week-input"
                type="week"
              >
            </label>
            <button
              :id="props.id"
              type="button"
              class="input has-suffix"
              :class="[
                `size-${props.size}`,
                `variant-${props.variant}`,
                {
                  errored: hasErrors,
                  clearable: !props.disabled && !props.required && model
                }
              ]"
              :disabled="props.disabled || props.readonly"
              @click="handleClick(toggle)"
            >
              {{ computedLabel }}
            </button>
            <span
              v-if="!props.disabled && !props.required && !props.readonly && model"
              class="input-suffix clear-button"
            >
              <button
                type="button"
                :title="t('common.Clear')"
                @click="handleClear"
              >
                <AlgIcon
                  name="cancel"
                  size="s"
                />
              </button>
            </span>
            <span class="input-suffix">
              <button
                type="button"
                :title="isOpen ? t('common.Close calendar') : t('common.Open calendar')"
                :disabled="props.disabled || props.readonly"
                @click="handleClick(toggle)"
              >
                <AlgIcon
                  name="calendar-today"
                  :color="
                    props.disabled
                      ? 'var(--alg-color-icon-unselected)'
                      : 'var(--alg-color-icon-highlight)'
                  "
                  size="s"
                />
              </button>
            </span>
          </div>
        </template>
      </AlgCalendarPopper>
      <AlgErrors
        v-if="props.errors && props.errors.length"
        :errors="props.errors"
      />
    </div>
  </div>
</template>

<style src="../index.css" scoped />

<style scoped>
  .input::-webkit-calendar-picker-indicator {
    display: none;
  }

  .week-input {
    display: none;
  }
</style>
