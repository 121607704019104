<script setup lang="ts">
  import { computed } from 'vue'

  import { abbreviationOf, Nullable } from '@algorh/shared'

  import { IconName, IconSize } from '#/types'

  import { AlgDot } from '../../dot'
  import { AlgIcon } from '../../media'

  import hatched from './hatched.png'
  import { ActivityCategory, PlanningActivityCardActivity } from './PlanningActivityCard.type'

  defineOptions({
    name: 'AlgPlanningActivityCard',
  })
  const props = withDefaults(defineProps<Props>(), {
    activity: null,
    mode: 'vertical',
    detailled: false,
    notWorked: false,
    forbidden: false,
    isDeleting: false,
    hasWarning: false,
    hasError: false,
    hasComment: false,
    showTitle: true,
  })

  interface Props {
    activity?: Nullable<PlanningActivityCardActivity>
    internalTaskColor?: Nullable<string>
    notWorked?: boolean
    mode?: 'horizontal' | 'vertical'
    notification?: 'pending' | 'accepted'
    detailled?: boolean
    duration?: string
    inactive?: boolean
    isLoading?: boolean
    isDeleting?: boolean
    hasWarning?: boolean
    hasError?: boolean
    hasComment?: boolean
    forbidden?: boolean
    warning?: boolean
    hideDetails?: boolean
    size?: 'm' | 'l' | 'xl' | 'xxl'
    showTitle?: boolean
  }

  const isFillingSlot = computed(() => props.activity?.category === ActivityCategory.LUNCH_BREAK)

  const isHatched = computed((): boolean => {
    if (!props.activity) {
      return false
    }
    const selectableNotSchedulable = props.activity.selectable === true && !props.activity.schedulable
    return isFillingSlot.value || selectableNotSchedulable
  })

  const activityStyle = computed(() => {
    if (props.forbidden) {
      return {
        // background-color: 'var(--alg-color-state-danger)',
        color: 'var(--alg-color-surface-primary)',
      }
    }
    if (!props.activity) {
      return {}
    }
    if (props.activity.category === ActivityCategory.NON_PRODUCTION && props.internalTaskColor) {
      return {
        background: props.internalTaskColor,
        color: `${
          isHatched.value
            ? 'var(--alg-color-text-secondary)'
            : 'var(--alg-color-text-on-color)'
        }`,
      }
    }
    const secondary
      = props.activity.secondary_color || 'var(--alg-color-surface-border)'
    const primary
      = props.activity.primary_color || 'var(--alg-color-surface-border)'
    const gradient = `linear-gradient(-45deg, ${secondary} 0%, ${primary} 100%)`
    const bg = isHatched.value ? `url('${hatched}'), ` : ''
    return {
      background: `${bg}${gradient}`,
      color: `${
        isHatched.value
          ? 'var(--alg-color-text-secondary)'
          : 'var(--alg-color-text-on-color)'
      }`,
    }
  })

  const iconName = computed(() =>
    props.forbidden
      ? 'block' as IconName
      : props.activity?.icon
        ? (`activity-${props.activity.icon}` as IconName)
        : null,
  )

  const iconSize = computed<IconSize>(() => {
    switch (props.size) {
    case 'm':
      return 'xs'
    case 'l':
      return 's'
    case 'xl':
      return 'm'
    case 'xxl':
      return 'm'
    default:
      return 'm'
    }
  })
</script>

<template>
  <div
    v-if="props.activity && !props.forbidden && !props.warning"
    class="planning-activity"
    :class="[
      props.mode,
      `size-${props.size}`,
      {
        'is-hatched': isHatched,
        'is-filling-slot': isFillingSlot,
        'is-detailled': props.detailled,
        'is-loading': props.isLoading,
        'is-inactive': props.inactive && !props.isLoading,
        'has-warning': props.hasWarning,
        'has-error': props.hasError,
        'has-comment': props.hasComment,
      },
    ]"
    :style="activityStyle"
    :title="showTitle ? props.activity.name : undefined"
  >
    <span class="status-icon">
      <AlgIcon
        v-if="props.hasWarning && mode === 'vertical'"
        color="var(--alg-color-state-warning)"
        name="warning"
        size="s"
      />
      <AlgIcon
        v-else-if="props.hasError"
        color="var(--alg-color-state-danger)"
        name="cancel"
        size="s"
      />
      <AlgDot
        v-else-if="props.hasComment"
        color="var(--alg-color-state-info)"
        size="xs"
        bordered
      />
    </span>
    <span
      v-if="props.notification"
      class="notification-indicator"
      :class="props.notification"
    />
    <template v-if="!props.hideDetails">
      <span
        v-if="iconName"
        class="icon"
      >
        <AlgIcon
          :name="iconName"
          :size="iconSize"
        />
      </span>
      <template v-else>
        <span
          v-if="!isFillingSlot && !props.detailled"
          class="abbreviation"
        >
          {{ props.activity.name ? abbreviationOf(props.activity.name) : "##" }}
        </span>
      </template>
      <template v-if="props.detailled">
        <span class="name">
          {{ props.activity.name }}
        </span>
        <span class="duration">
          {{ props.duration }}
        </span>
      </template>
    </template>
  </div>
  <div
    v-else-if="notWorked"
    class="planning-activity is-empty"
    :style="{ ...activityStyle }"
  />
  <div
    v-else-if="props.forbidden"
    class="planning-activity is-forbidden"
    :class="{ 'is-loading': props.isLoading }"
    :style="{ ...activityStyle }"
  >
    <span class="icon">
      <AlgIcon
        name="block"
        size="s"
      />
    </span>
  </div>
  <div
    v-else-if="props.warning"
    class="planning-activity is-warning"
  >
    <span class="icon">
      <AlgIcon
        name="warning"
        size="s"
      />
    </span>
  </div>
  <div
    v-else-if="isDeleting"
    class="planning-activity is-deleting"
    :class="{ 'is-loading': props.isLoading }"
    :style="{ ...activityStyle }"
  >
    <span class="icon">
      <AlgIcon
        name="delete"
        size="s"
      />
    </span>
  </div>
  <div
    v-else
    class="planning-activity"
    :style="{ ...activityStyle }"
  />
</template>

<style scoped>
.planning-activity {
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--alg-effect-radius-s);
  background-repeat: repeat-x;
  background-size: 12px auto;
  line-height: inherit;

  &.is-deleting {
    position: relative;
    border-radius: var(--alg-effect-radius-s);
    background-color: var(--alg-color-state-danger);
    color: var(--alg-color-icon-on-color);
  }

  &.is-forbidden {
    position: relative;
    border-radius: var(--alg-effect-radius-s);
    background: var(--alg-color-state-danger);
    color: var(--alg-color-icon-on-color);
  }

  &.is-warning {
    position: relative;
    border-radius: var(--alg-effect-radius-s);
    background: var(--alg-color-state-warning);
    color: var(--alg-color-icon-on-color);
  }

  .status-icon {
    position: absolute;
    top: 4px;
    left: 4px;
  }

  &.is-empty {
    border-radius: 0;
    background-image: linear-gradient(
      90deg,
      var(--alg-color-transparent) 0,
      var(--alg-color-surface-border-dark) 0,
      var(--alg-color-surface-border-dark) 2px,
      var(--alg-color-transparent) 2px,
      var(--alg-color-transparent) 6px,
      var(--alg-color-surface-border-dark) 6px,
      var(--alg-color-surface-border-dark) 8px,
      var(--alg-color-transparent) 8px,
      var(--alg-color-transparent) 12px
    );
    pointer-events: none;
  }

  &.horizontal {
    width: 100%;
  }

  &.vertical {
    height: 100%;
  }

  .notification-indicator {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border: 1px solid var(--alg-color-light);
    border-radius: 50%;
    box-shadow: var(--alg-box-shadow-s);

    &.pending {
      background-color: var(--alg-color-state-danger);
    }

    &.accepted {
      background-color: var(--alg-color-state-success);
    }
  }

  .abbreviation,
  .name,
  .duration {
    box-sizing: border-box;
    color: var(--alg-color-light);
    line-height: inherit;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .abbreviation {
    font-weight: var(--alg-font-weight-bold);
    user-select: none;
  }

  .name {
    overflow: hidden;
    width: 100%;
    padding: var(--alg-spacing-s) var(--alg-spacing-s) var(--alg-spacing-xs);
    font-size: 14px;
    font-weight: var(--alg-font-weight-bold);
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .duration {
    width: 100%;
    padding: var(--alg-spacing-xs) var(--alg-spacing-s) var(--alg-spacing-s);
    font-size: 12px;
    font-weight: var(--alg-font-weight-regular);
    text-align: center;
  }

  &.is-filling-slot {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;
    margin-right: 0;
    margin-left: 0;
  }

  &.is-hatched:not(.forbidden) {
    .abbreviation,
    .icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--alg-color-surface-primary);
      color: var(--alg-color-text-secondary);
    }
  }

  &.is-loading {
    opacity: 0.5;
  }

  &.is-detailled {
    width: calc(100% - 8px);
  }

  &.is-inactive {
    border: 1px solid var(--alg-color-surface-border);
    background: var(--alg-color-surface-secondary) !important;

    .icon,
    .abbreviation {
      color: var(--alg-color-text-primary);
    }
  }

  &.has-warning {
    border: 1px solid var(--alg-color-state-warning);
    border-radius: var(--alg-effect-radius-s);

    &.horizontal {
      width: calc(100% - 4px);
      border: none;
      margin: 0 2px;
      outline: 2px solid var(--alg-color-state-warning);
    }
  }

  &.has-error {
    border: 1px solid var(--alg-color-state-danger);
    border-radius: var(--alg-effect-radius-s);
  }

  &.has-comment {
    .status-icon {
      right: 4px;
      left: auto;
    }
  }

  &.size-m {
    .abbreviation {
      font-size: var(--alg-font-size-s);
      line-height: var(--alg-font-size-s);
    }

    &.is-hatched {
      .icon,
      .abbreviation {
        width: 18px;
        height: 18px;
      }
    }
  }

  &.size-l {
    .abbreviation {
      font-size: var(--alg-font-size-m);
      line-height: var(--alg-font-size-m);
    }

    &.is-hatched {
      .icon,
      .abbreviation {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.size-xl {
    .abbreviation {
      font-size: var(--alg-font-size-l);
      line-height: var(--alg-font-size-l);
    }

    &.is-hatched {
      .icon,
      .abbreviation {
        width: 28px;
        height: 28px;
      }
    }
  }

  &.size-xxl {
    .abbreviation {
      font-size: var(--alg-font-size-xl);
      line-height: var(--alg-font-size-xl);
    }

    &.is-hatched {
      .icon,
      .abbreviation {
        width: 34px;
        height: 34px;
      }
    }
  }
}
</style>
