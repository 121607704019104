<script lang="ts" setup>
  import { computed } from 'vue'

  import { FetchErrorObj, Nullable } from '@algorh/shared'
  import { AlgNumberInput, AlgSelect } from '@algorh/ui'

  import { RuleComparison, RuleOperator } from '@/core/enums/Rule'

  import { RuleDto } from '@/sections/settings/services'

  import RuleDurationSelect from '../../inputs/RuleDurationSelect.vue'
  import RuleSubjectSelect from '../../inputs/RuleSubjectSelect.vue'
  import { selectOptions } from '../ruleForms'

  type Model = Pick<RuleDto, 'config' | 'subject_id' | 'subject_type' | 'value'>
  type Props = {
    readonly errors: Nullable<FetchErrorObj<Model>>
    readonly activityFamilies: { id: number, name: string }[]
    readonly activities: { id: number, name: string }[]
  }

  defineProps<Props>()

  const model = defineModel<Model>({ required: true })

  const subject = computed(() => ({ type: model.value.subject_type, id: model.value.subject_id }))

  function handleComparisonUpdate(comparison: Nullable<RuleComparison>) {
    model.value = { ...model.value, config: model.value.config
      ? { ...model.value.config, comparison: comparison ?? undefined }
      : null }
  }

  function handleValueUpdate(value: Nullable<number>) {
    model.value = { ...model.value, value: value ?? 1 }
  }

  function handleConfigOperatorUpdate(operator: Nullable<RuleOperator>) {
    model.value = { ...model.value, config: model.value.config
      ? { ...model.value.config, operator: operator ?? undefined }
      : null,
    }
  }

  function handleConfigDurationUpdate(duration: Nullable<number>) {
    model.value = { ...model.value, config: model.value.config
      ? { ...model.value.config, duration }
      : null,
    }
  }

  function handleSubjectUpdate(s: typeof subject.value) {
    model.value = {
      ...model.value,
      subject_type: s.type,
      subject_id: s.id,
    }
  }

</script>

<template>
  <div class="rule-form">
    <AlgSelect
      id="config-comparison"
      class="choice-fields has-counter"
      centered
      required
      :options="selectOptions('rules', Object.values(RuleComparison))"
      :errors="errors?.errors?.['config.comparison']"
      :model-value="model.config?.comparison ?? null"
      @update:model-value="handleComparisonUpdate"
    />
    <AlgNumberInput
      id="value"
      class="choice-fields has-counter"
      :errors="errors?.errors?.value"
      :model-value="model.value"
      centered
      @update:model-value="handleValueUpdate"
    />
    <AlgSelect
      id="config-comparison"
      class="choice-fields has-counter"
      centered
      required
      :options="selectOptions('rules', Object.values(RuleOperator))"
      :errors="errors?.errors?.['config.operator']"
      :model-value="model.config?.operator ?? RuleOperator['=']"
      @update:model-value="handleConfigOperatorUpdate"
    />
    <RuleDurationSelect
      :has-any-durationvalue="false"
      name="duration"
      class="choice-fields has-counter"
      :errors="errors?.errors?.['config.duration']"
      :model-value="model.config?.duration ?? null"
      @update:model-value="handleConfigDurationUpdate"
    />
    <RuleSubjectSelect
      name="subject"
      nullable
      class="choice-fields has-counter"
      :activity-families="activityFamilies"
      :activities="activities"
      :errors="errors?.errors?.subject_id"
      :model-value="subject"
      @update:model-value="handleSubjectUpdate"
    />
  </div>
</template>

<style src="@/components/rules-ambitions/rules/forms/ruleForms.css" />
