import { DateTimeString, fetchJson } from '@algorh/shared'

import { LoadCurveDayTolerance, LoadCurveSlotTolerance } from '../types/Planning'

const BASE_URI = '/api/scheduling/load-curves'

const SchedulingLoadCurvesApiService = {
  putDayToleranceMesh(toleranceId: number, mesh: string) {
    return fetchJson<LoadCurveDayTolerance, { mesh: string }>(
      'PUT', `${BASE_URI}/${toleranceId}/day-tolerance-mesh`, { mesh },
    )
  },
  putDayToleranceValues(toleranceId: number, min: number, max: number, datetime: string) {
    return fetchJson<LoadCurveDayTolerance, { min: number, max: number, datetime: string }>(
      'PUT', `${BASE_URI}/${toleranceId}/day-tolerance-values`, { min, max, datetime },
    )
  },
  getIncompleteSlots(planningId: number) {
    return fetchJson<DateTimeString[]>(
      'GET', `${BASE_URI}/${planningId}/incomplete-slots`,
    )
  },
  putSlotToleranceValues(toleranceId: number, min: number, max: number, datetime: string) {
    return fetchJson<LoadCurveSlotTolerance, { min: number, max: number, datetime: string }>(
      'PUT', `${BASE_URI}/${toleranceId}/slot-tolerance-values`, { min, max, datetime },
    )
  },
  putSlotToleranceMesh(toleranceId: number, mesh: string) {
    return fetchJson<LoadCurveSlotTolerance, { mesh: string }>(
      'PUT', `${BASE_URI}/${toleranceId}/slot-tolerance-mesh`, { mesh },
    )
  },
}

export { SchedulingLoadCurvesApiService }
