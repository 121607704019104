<script
  setup
  lang="ts"
  generic="T extends { id?: R } & Record<string, any>, E extends string = any, R extends number | string = number"
>
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import { Nullable, PaginationMeta } from '@algorh/shared'

  import { AlgButton } from '../button'
  import { AlgNoData, AlgSpinner } from '../feedback'
  import { AlgCheckbox } from '../form'
  import { AlgPagination } from '../pagination'
  import { AlgSelectionChip } from '../selection-chip'

  import { AlgTableField, AlgTableSort, AlgTableSortDirection, TableVariant } from './Table.types'

  type Props = {
    readonly id: string
    readonly items?: T[]
    readonly fields?: AlgTableField<T, E>[]
    readonly pagination?: boolean
    readonly paginationMeta?: Nullable<PaginationMeta>
    readonly loading?: boolean
    readonly sticky?: boolean
    readonly clickable?: boolean
    readonly selection?: boolean
    readonly selectedRows?: R[]
    readonly variant?: TableVariant
    readonly relative?: boolean
    readonly exportable?: boolean
    readonly exportLoading?: boolean
    readonly exportLabel?: string
    readonly selectCount?: boolean
    readonly showDetails?: boolean
  }

  defineOptions({
    name: 'AlgTable',
  })

  const props = withDefaults(defineProps<Props>(), {
    items: () => [],
    fields: () => [],
    selectedRows: () => [],
    selection: false,
    pagination: false,
    loading: false,
    relative: false,
    sticky: false,
    clickable: false,
    paginationMeta: null,
    variant: 'default',
    exportable: false,
    exportLoading: false,
    selectCount: false,
    showDetails: true,
  })

  const emit = defineEmits<{
    (e: 'row-click', p: T): void
    (e: 'page-change', p: number): void
    (e: 'selection-change', ids: R[], checked?: boolean): void
    (e: 'export'): void
  }>()

  const slot = defineSlots<
    {
      [K in `cell(${string})`]?: (props: { item: T }) => unknown;
    } & {
      [K in `head(${string})`]?: (props: {
        field: AlgTableField<T, E>
      }) => unknown;
    } & {
      empty?: { value: string }
    }
  >()

  // Composables
  const { t } = useI18n()

  // Models
  const sort = defineModel<Nullable<AlgTableSort<T>>>('sort', { default: null })

  // Computed
  const itemsIds = computed(() => props.items.map(({ id }) => id as R))

  const selectedItemsInPage = computed(() => props.selectedRows.filter((i) => itemsIds.value.includes(i)))

  const allSelected = computed({
    get() {
      return props.items.length === selectedItemsInPage.value.length && selectedItemsInPage.value.length !== 0
    },
    set(value) {
      if (props.pagination) {
        emit('selection-change', itemsIds.value, value)
      } else {
        emit('selection-change', value ? itemsIds.value : [])
      }
    },
  })

  const indeterminate = computed(() => selectedItemsInPage.value.length > 0 && !allSelected.value)

  const resultsCount = computed(() => props.paginationMeta?.total ?? props.items.length)

  // Methods
  function handleExport() {
    emit('export')
  }

  const sortCycle = new Map<AlgTableSortDirection, AlgTableSortDirection | undefined>([
    ['asc', 'desc'],
    ['desc', undefined],
    [undefined, 'asc'],
  ])

  function handleSort(field: AlgTableField<T, E>) {
    const nextDirection = sortCycle.get(sort.value?.direction)

    sort.value = {
      sort_by: nextDirection && (field.sortKeyPath ?? field.key),
      direction: nextDirection,
    }
  }

  function handleRowClick(item: T) {
    if (!props.clickable) {
      return
    }

    emit('row-click', item)
  }

  function handlePaginationChange(page: number) {
    emit('page-change', page)
  }

  function handleSelectItem(value: R[], itemId?: R) {
    if (!itemId) {
      return
    }
    if (props.pagination) {
      emit('selection-change', [itemId], value.includes(itemId))
    } else {
      emit('selection-change', value)
    }
  }
</script>

<template>
  <div
    :id="props.id"
    class="table"
    :class="`variant-${props.variant}`"
  >
    <div
      v-if="props.showDetails && !props.loading"
      class="table-details"
    >
      <div
        class="results-count"
      >
        {{ t('common.{n} results', {n: resultsCount }, resultsCount) }}
      </div>
      <AlgSelectionChip
        v-if="props.selectCount && props.selection && props.selectedRows.length > 0"
        :count="props.selectedRows.length"
        @cancel="emit('selection-change', selectedRows.map((i) => i), false)"
      />
      <div>
        <AlgButton
          v-if="props.exportable"
          class="export-button"
          variant="secondary"
          icon-start="download-cloud"
          :loading="props.exportLoading"
          :disabled="!props.items.length"
          :label="props.exportLabel ?? t('common.Export')"
          @cancel="() => emit('selection-change', props.selectedRows)"
          @click="handleExport"
        />
      </div>
    </div>
    <div
      class="table-container"
    >
      <div
        v-if="props.loading"
        class="loading"
      >
        <AlgSpinner size="m" />
      </div>
      <div
        class="table-inner"
        :class="{
          relative: props.relative
        }"
        role="table"
      >
        <div
          class="table-header"
          :class="{
            'sticky': props.sticky,
          }"
          role="rowgroup"
        >
          <div
            class="table-row"
            role="row"
          >
            <div
              v-if="props.selection"
              class="table-h selection"
              role="cell"
            >
              <AlgCheckbox
                :id="`${props.id}-select-all`"
                v-model="allSelected"
                :disabled="!props.items.length"
                :inactive="props.loading"
                :indeterminate="indeterminate"
                :aria-label="t('common.Select all')"
              />
            </div>
            <template v-for="(field, i) in props.fields">
              <div
                v-if="!field.hidden"
                :key="i"
                class="table-h"
                :role="field.label === '' ? 'cell' : 'columnheader'"
              >
                <div
                  class="table-cell-content"
                  :class="`justify-${field.justify ?? 'start'}`"
                >
                  <AlgButton
                    v-if="field.sortable"
                    :id="`head-${field.key}`"
                    :inactive="props.loading"
                    size="s"
                    icon-end="expand-more"
                    :icon-end-rotate="sort?.sort_by === (field.sortKeyPath ?? field.key)
                      ? sort.direction === 'desc'
                        ? 0
                        : sort.direction === 'asc'
                          ? 180
                          : 0
                      : 0
                    "
                    :icon-end-color="sort?.sort_by === (field.sortKeyPath ?? field.key)
                      ? sort.direction === undefined
                        ? 'var(--alg-color-icon-unselected)'
                        : 'inherit'
                      : 'var(--alg-color-icon-unselected)'
                    "
                    variant="transparent"
                    @click="() => handleSort(field)"
                  >
                    <slot
                      :name="`head(${field.key})`"
                      :field="field"
                    >
                      {{ field.label }}
                    </slot>
                  </AlgButton>
                  <slot
                    v-else
                    :name="`head(${field.key})`"
                    :field="field"
                  >
                    {{ field.label }}
                  </slot>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="items.length"
          class="table-body"
          role="rowgroup"
        >
          <div
            v-for="(item, k) in props.items"
            :key="k"
            role="row"
            tabindex="0"
            :class="[
              'table-row',
              {
                'clickable': props.clickable,
                'selected': item.id ? props.selectedRows.includes(item.id) : false,
              }
            ]"
            @click.stop="() => handleRowClick(item)"
            @keydown.enter.stop="() => handleRowClick(item)"
          >
            <div
              v-if="props.selection"
              role="cell"
              class="table-d"
            >
              <AlgCheckbox
                :id="`${props.id}-${item.id}-select`"
                :model-value="selectedItemsInPage"
                :true-value="item.id"
                :inactive="props.loading"
                :aria-label="t('common.Select')"
                @update:model-value="(value: R[]) => handleSelectItem(value, item.id)"
              />
            </div>
            <template v-for="field in props.fields">
              <div
                v-if="!field.hidden"
                :key="field.key"
                class="table-d"
                :class=" {
                  'capitalize': field.capitalize,
                }"
                role="cell"
              >
                <div
                  class="table-cell-content"
                  :class="`justify-${field.justify ?? 'start'}`"
                >
                  <slot
                    :name="`cell(${field.key})`"
                    :item="item"
                  >
                    {{ item[field.key] }}
                  </slot>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="!props.loading && !props.items.length"
        class="table-empty"
      >
        <slot name="empty">
          <AlgNoData
            illustration="not-found"
            :title="t('common.No data')"
          />
        </slot>
      </div>
    </div>
    <div
      v-if="props.pagination && props.paginationMeta?.total"
      class="table-pagination"
    >
      <AlgPagination
        :loading="props.loading"
        :total="paginationMeta?.total"
        :current="paginationMeta?.current_page"
        :from="paginationMeta?.from"
        :to="paginationMeta?.to"
        @change="handlePaginationChange"
      />
    </div>
  </div>
</template>

<style scoped>
.table {
  display: flex;
  min-width: 0;
  min-height: 96px;
  flex: 1 1 auto;
  flex-direction: column;
  color: var(--alg-color-text-primary);

  .table-details {
    display: flex;
    min-height: 48px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: var(--alg-spacing-s) var(--alg-spacing-l);
    gap: var(--alg-spacing-m);

    .results-count {
      font-size: var(--alg-font-size-m);
      font-weight: var(--alg-font-weight-bold);
    }

    .export-button {
      margin-left: auto;
    }
  }

  .table-container {
    position: relative;
    display: flex;
    overflow: auto;
    min-height: 0;
    flex: 1 1 auto;
    flex-direction: column;

    .loading {
      position: absolute;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(255 255 255 / 50%);
      inset: 0;
    }

    .table-inner {
      display: table;
      flex: 0 1 auto;

      &.relative {
        position: relative;
        flex: 1 1 auto;
      }

      .table-header,
      .table-body {
        display: table-row-group;

        .table-row {
          display: table-row;
          white-space: pre;

          .table-h,
          .table-d {
            display: table-cell;
            overflow: hidden;
            padding: var(--alg-spacing-m);
            font-weight: var(--alg-font-weight-bold);
            vertical-align: middle;

            &:first-child {
              padding-left: var(--alg-spacing-l);
            }

            &:last-child {
              padding-right: var(--alg-spacing-l);
            }

            .table-cell-content {
              display: flex;
              align-items: center;
              gap: var(--alg-spacing-xs);

              .button {
                padding: 0;
              }

              &.justify-start {
                justify-content: start;
              }

              &.justify-center {
                justify-content: center;
              }

              &.justify-end {
                justify-content: flex-end;
              }
            }
          }
        }
      }

      .table-header {
        box-shadow: var(--alg-effect-shadow-s);

        .table-h {
          padding: var(--alg-spacing-l);
          font-size: var(--alg-font-size-s);
          gap: var(--alg-spacing-s);

          &.selection {
            width: 56px;
            box-sizing: border-box;
          }
        }

        &.sticky {
          position: sticky;
          z-index: 4;
          top: 0;
          background-color: var(--alg-color-surface-primary);
        }
      }

      .table-body {
        .table-row {
          .table-d {
            color: var(--alg-color-text-light);
            font-size: var(--alg-font-size-xs);

            &.capitalize {
              text-transform: capitalize;
            }
          }

          &.clickable {
            cursor: pointer;
          }

          &.selected {
            background-color: var(--alg-color-surface-background-highlight);

            .table-d {
              color: var(--alg-color-text-highlight);
            }
          }

          &:hover {
            background-color: var(--alg-color-surface-background-highlight);

            .table-d {
              color: var(--alg-color-text-highlight);
            }
          }
        }

        &::after {
          display: table-row;
          height: 100%;
          content: '';
        }
      }
    }

    .table-empty {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
      padding: var(--alg-spacing-l);
    }
  }

  &.variant-bordered {
    .table-header {
      .table-h {
        border-bottom: 1px solid var(--alg-color-surface-border);
        color: var(--alg-color-text-secondary);
        text-transform: uppercase;
      }
    }

    .table-body {
      .table-d {
        border-bottom: 1px solid var(--alg-color-surface-border);
      }

      .table-row {
        &:last-child {
          .table-d {
            border-bottom: none;
          }
        }
      }
    }
  }

  &.variant-stripped {
    .table-body {
      .table-row:nth-child(odd) {
        background-color: var(--alg-color-surface-background);

        &:hover {
          background-color: var(--alg-color-surface-background-highlight);

          .table-d {
            color: var(--alg-color-text-highlight);
          }
        }
      }
    }
  }

  .table-pagination {
    flex: 0 0 auto;
    padding: var(--alg-spacing-m) var(--alg-spacing-l);
    border-top: 1px solid var(--alg-color-surface-border);
  }
}
</style>
