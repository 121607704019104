<script setup lang="ts" generic="K extends string, V extends FilterValue, M extends Record<string, FilterValue>,E extends Nullable<FetchErrorObj<any>>">
  import { computed } from 'vue'
  import { useI18n } from 'vue-i18n'

  import {
    DateString,
    dayjs,
    DTF,
    FetchErrorObj,
    FilterCombinationOptions,
    FilterOption,
    FilterValue,
    MultiselectFilter,
    Nullable,
    SelectFilter,
    ShortTimeString,
    SubdivisionFilter,
  } from '@algorh/shared'

  import { AlgButton } from '../../button'
  import { AlgChip } from '../../chip'
  import { AlgDisclosure } from '../../disclosure'
  import { AlgDotLoader } from '../../feedback'
  import { AlgSearchInput, AlgToggle, ChoiceTreeData } from '../../form'
  import { AlgCombinationFilter } from '../combination-filter'
  import { AlgMultiselectFilter } from '../multiselect-filter'
  import { AlgPeriodFilter } from '../period-filter'
  import { AlgSelectFilter } from '../select-filter'
  import { AlgSubdivisionFilter } from '../subdivision-filter'

  type Props = {
    readonly options: Record<K, FilterOption> | FilterCombinationOptions<K>[]
    readonly modelValue: M
    readonly errors?: E
    readonly combination?: Nullable<number>
    readonly open?: boolean
  }

  defineOptions({
    name: 'AlgFilters',
  })

  const props = withDefaults(defineProps<Props>(), {
    open: false,
  })

  const emit = defineEmits<{
    (e: 'update:model-value', value: typeof props.modelValue): void
    (e: 'update:custom-filters',
     action: 'delete' | 'update' | 'create',
     label: Nullable<string>,
     id: Nullable<number>
    ): void
    (e: 'apply:custom-filter', value: Nullable<number>): void
    (e: 'reset'): void
  }>()

  // Composables
  const { t } = useI18n()

  // Computed
  const flattedOptions = computed((): Record<K, FilterOption & { color?: string }> => {
    let options = {} as Record<K, FilterOption>
    if (Array.isArray(props.options)) {
      for (const obj of props.options) {
        const filters = Object.entries(obj.filters).map(([k, v]) => ([k as K, { ...v as FilterOption, color: obj.color }]))
        options = { ...options, ...Object.fromEntries(filters) }
      }
    } else {
      options = props.options
    }
    return options
  })
  const optionsGroups = computed((): FilterCombinationOptions<K>[] =>
    Array.isArray(props.options) ? props.options : [{ filters: props.options }],
  )
  const displayResetButton = computed(() => {
    return (Object.entries(flattedOptions.value) as [K, FilterOption][]).some(([key, option]) => {
      if (!props.modelValue || !props.modelValue[key]) {
        return false
      }

      if (option.type === 'date-period' || option.type === 'time-period') {
        return (props.modelValue[key] as Nullable<DateString>[]).some((v) => v !== null)
      }

      if (option.type === 'multiselect' || option.type === 'subdivision') {
        return (props.modelValue[key] as (string | number)[]).length > 0
      }

      return false
    })
  })

  // Methods
  function handleAddFilter(k: K, value: V) {
    emit('update:model-value', {
      ...props.modelValue,
      [k]: value,
    })
  }

  function handleUpdateCombination(value: Nullable<number>) {
    emit('apply:custom-filter', value)
  }

  function handleDeleteFilter(k: K) {
    switch (flattedOptions.value[k].type) {
    case 'date-period':
    case 'time-period':
      emit('update:model-value', { ...props.modelValue, [k]: [null, null] })
      break
    case 'multiselect':
      emit('update:model-value', { ...props.modelValue, [k]: [] })
      break
    case 'boolean':
      emit('update:model-value', { ...props.modelValue, [k]: false })
      break
    case 'select':
    default:
      emit('update:model-value', { ...props.modelValue, [k]: null })
      break
    }
  }

  function handleDeleteOption(k: K, value: number | string) {
    if (!props.modelValue) {
      return
    }

    emit('update:model-value', {
      ...props.modelValue,
      [k]: (props.modelValue[k] as (number | string)[]).filter((v) => v !== value),
    })
  }

  function handleResetFilters() {
    emit('reset')
  }

  function getDatePeriodChip([start, end]: DateString[]) {
    if (start && !end) {
      return t(
        'datetime.From {date}',
        {
          date: dayjs(start).format(t(`datetime.${DTF.DATE}`)),
        })
    } else if (!start && end) {
      return t(
        'datetime.Until {date}',
        {
          date: dayjs(end).format(t(`datetime.${DTF.DATE}`)),
        })
    } else if (start === end) {
      return t(
        'datetime.On {date}',
        {
          date: dayjs(start).format(t(`datetime.${DTF.DATE}`)),
        })
    } else {
      return t(
        'datetime.From {dateStart} to {dateEnd}',
        {
          dateStart: dayjs(start).format(t(`datetime.${DTF.DATE}`)),
          dateEnd: dayjs(end).format(t(`datetime.${DTF.DATE}`)),
        })
    }
  }

  function getTimePeriodChip([start, end]: ShortTimeString[]) {
    if (start && !end) {
      return t(
        'datetime.From {time}',
        {
          time: start,
        })
    } else if (!start && end) {
      return t(
        'datetime.Until {time}',
        {
          time: end,
        })
    } else {
      return t(
        'datetime.From {timeStart} to {timeEnd}',
        {
          timeStart: start,
          timeEnd: end,
        })
    }
  }

  function subdivisionsRecursive(data: ChoiceTreeData): { id: number, label: string }[] {
    return [...data.children.map((child) => {
      const { id, name: label, children } = child

      if (children.length) {
        return subdivisionsRecursive(child)
      }

      return { id, label }
    }).flat(), { id: data.id, label: data.name }]
  }

</script>

<template>
  <AlgDisclosure
    rounded
    bordered
    background-color="var(--alg-color-surface-primary)"
    class="filters"
    :open="props.open"
  >
    <template #trigger>
      <div class="filters-trigger">
        <span class="label">
          {{ t('common.Filter by:') }}
        </span>
        <div
          v-if="props.modelValue"
          class="chips"
        >
          <template
            v-for="(value, k) in (props.modelValue as M)"
          >
            <AlgChip
              v-if="flattedOptions[k]?.type === 'text' && value !== null"
              :id="k.toString()"
              :key="k"
              :label="`
                ${flattedOptions[k].label}
                ${t('typography.colon')}
                ${typeof value === 'string' ? value.split('|').join(', ') : value}
              `"
              :color="flattedOptions[k].color"
              :deletable="!flattedOptions[k].readonly"
              @delete="() => handleDeleteFilter(k as K)"
            />
            <AlgChip
              v-if="flattedOptions[k]?.type === 'select' && value !== null"
              :id="k.toString()"
              :key="k"
              :label="`
                ${flattedOptions[k].label}
                ${t('typography.colon')}
                ${(flattedOptions[k] as SelectFilter).options.find((o) => o.value === value)?.label}
              `"
              :color="flattedOptions[k].color"
              :deletable="!flattedOptions[k].readonly"
              @delete="() => handleDeleteFilter(k as K)"
            />
            <AlgChip
              v-if="flattedOptions[k]?.type === 'date-period'
                && (value as Nullable<DateString>[]).length > 0
                && (value as Nullable<DateString>[]).some(i => i !== null)"
              :id="k.toString()"
              :key="k"
              :label="`
                ${flattedOptions[k].label}
                ${t('typography.colon')}
                ${getDatePeriodChip(value as DateString[])}
              `"
              :color="flattedOptions[k].color"
              :deletable="!flattedOptions[k].readonly"
              @delete="() => handleDeleteFilter(k as K)"
            />
            <AlgChip
              v-if="flattedOptions[k]?.type === 'time-period'
                && (value as Nullable<ShortTimeString>[]).length > 0
                && (value as Nullable<ShortTimeString>[]).some(i => i !== null)"
              :id="k.toString()"
              :key="k"
              :label="`
                ${flattedOptions[k].label}
                ${t('typography.colon')}
                ${getTimePeriodChip(value as ShortTimeString[])}
              `"
              :color="flattedOptions[k].color"
              :deletable="!flattedOptions[k].readonly"
              @delete="() => handleDeleteFilter(k as K)"
            />
            <template
              v-if="flattedOptions[k]?.type === 'multiselect'"
            >
              <AlgChip
                v-for="(v, l) in value"
                :id="`${k.toString()}-${l.toString()}`"
                :key="l"
                :color="flattedOptions[k].color"
                :deletable="
                  !flattedOptions[k].readonly
                    && !!(flattedOptions[k] as MultiselectFilter).options.find((o) => o.value === v)?.label"
                @delete="() => handleDeleteOption(k as K, v as string | number)"
              >
                <template v-if="(flattedOptions[k] as MultiselectFilter).options.find((o) => o.value === v)?.label">
                  {{ (flattedOptions[k] as MultiselectFilter).options.find((o) => o.value === v)?.label }}
                </template>
                <template v-else>
                  <AlgDotLoader
                    size="xs"
                    variant="secondary"
                  />
                </template>
              </AlgChip>
            </template>
            <template
              v-if="flattedOptions[k]?.type === 'subdivision'"
            >
              <template v-for="subdivisions in (flattedOptions[k] as SubdivisionFilter).subdivisions">
                <template
                  v-for="subdivision in subdivisionsRecursive(subdivisions)"
                >
                  <AlgChip
                    v-if="value && Array.isArray(value) ? value.map(Number).includes(subdivision.id) : false"
                    :id="`${k.toString()}-${subdivision.id}`"
                    :key="`${k.toString()}-${subdivision.id}`"
                    :deletable="!flattedOptions[k].readonly"
                    :color="flattedOptions[k].color"
                    @delete="() => handleDeleteOption(k as K, subdivision.id)"
                  >
                    {{ subdivision.label }}
                  </AlgChip>
                </template>
              </template>
            </template>
            <AlgChip
              v-if="flattedOptions[k]?.type === 'boolean' && value === true"
              :id="k.toString()"
              :key="k"
              :label="`${flattedOptions[k].label}`"
              :deletable="!flattedOptions[k].readonly"
              :color="flattedOptions[k].color"
              @delete="() => handleDeleteFilter(k as K)"
            />
          </template>
        </div>
        <div
          v-if="displayResetButton"
          class="reset-button"
        >
          <AlgButton
            variant="link"
            size="s"
            :label="t('common.Reset')"
            @click.stop="handleResetFilters"
          />
        </div>
      </div>
    </template>
    <div
      v-for="(optionsGroup, j) in optionsGroups"
      :key="j"
      class="filters-group"
    >
      <span
        v-if="optionsGroup.label"
        class="filters-group-label"
        :style="{color: optionsGroup.color ?? 'inherit' }"
      >
        {{ optionsGroup.label }}
      </span>
      <div
        class="filters-content"
      >
        <AlgCombinationFilter
          v-if="optionsGroup.combinationOptions"
          :label="t('common.Custom filters')"
          :options="optionsGroup.combinationOptions"
          :model-value="props.combination ?? null"
          :color="optionsGroup.color"
          @update:model-value="handleUpdateCombination"
          @create="(label) => emit('update:custom-filters', 'create', label, null)"
          @update="(label, id) => emit('update:custom-filters', 'update', label, id)"
          @delete="(id) => emit('update:custom-filters', 'delete', null, id)"
        />
        <template
          v-for="(option, k) in optionsGroup.filters"
        >
          <template v-if="props.modelValue && !option.unavailable">
            <AlgSearchInput
              v-if="option.type === 'text'"
              :id="k.toString()"
              :key="k"
              variant="underline"
              :placeholder="option.label"
              :errors="Object.entries(props.errors?.errors ?? {}).filter(([key]) => option.errorKeys?.includes(key as string)).flatMap(([, value]) => value)"
              :readonly="option.readonly"
              :disabled="option.disabled"
              :color="optionsGroup.color"
              :model-value="(props.modelValue[k] as Nullable<string>)"
              @update:model-value="(v) => handleAddFilter(k, v as V)"
            />
            <AlgSelectFilter
              v-if="option.type === 'select'"
              :id="k.toString()"
              :key="k"
              :label="option.label"
              :options="option.options"
              :option-groups="option.optionGroups"
              :searchable="option.searchable"
              :readonly="option.readonly"
              :disabled="option.disabled"
              :model-value="(props.modelValue[k] as Nullable<string | number>)"
              @update:model-value="(v) => handleAddFilter(k, v as V)"
            />
            <AlgMultiselectFilter
              v-if="option.type === 'multiselect'"
              :id="k.toString()"
              :key="k"
              :label="option.label"
              :options="option.options"
              :option-groups="option.optionGroups"
              :option-sub-groups="option.optionSubGroups"
              :searchable="option.searchable"
              :readonly="option.readonly"
              :disabled="option.disabled"
              :color="optionsGroup.color"
              :model-value="((props.modelValue[k] ?? []) as (string | number)[])"
              @update:model-value="(v) => handleAddFilter(k, v as V)"
            />
            <AlgPeriodFilter
              v-if="option.type === 'date-period'"
              :id="k.toString()"
              :key="k"
              mode="date"
              :type="option.type"
              :label="option.label"
              :readonly="option.readonly"
              :disabled="option.disabled"
              :color="optionsGroup.color"
              :errors="Object.entries(props.errors?.errors ?? {}).filter(([key]) => option.errorKeys?.includes(key as string)).flatMap(([, value]) => value)"
              :model-value="(props.modelValue[k] as [Nullable<DateString>, Nullable<DateString>])"
              @update:model-value="(v: [Nullable<DateString>, Nullable<DateString>]) => handleAddFilter(k, v as V)"
            />
            <AlgPeriodFilter
              v-if="option.type === 'time-period'"
              :id="k.toString()"
              :key="k"
              mode="time"
              :type="option.type"
              :label="option.label"
              :readonly="option.readonly"
              :disabled="option.disabled"
              :color="optionsGroup.color"
              :errors="Object.entries(props.errors?.errors ?? {}).filter(([key]) => option.errorKeys?.includes(key as string)).flatMap(([, value]) => value)"
              :model-value="(props.modelValue[k] as [Nullable<ShortTimeString>, Nullable<ShortTimeString>])"
              @update:model-value="(v: [Nullable<ShortTimeString>, Nullable<ShortTimeString>]) => handleAddFilter(k, v as V)"
            />
            <AlgSubdivisionFilter
              v-if="option.type === 'subdivision'"
              :id="k.toString()"
              :key="k"
              :label="option.label"
              :subdivisions="option.subdivisions"
              :readonly="option.readonly"
              :disabled="option.disabled"
              :color="optionsGroup.color"
              :model-value="((props.modelValue[k] ?? []) as number[])"
              @update:model-value="(v) => handleAddFilter(k, v as V)"
            />
            <AlgToggle
              v-if="option.type === 'boolean'"
              :id="k.toString()"
              :key="k"
              class="boolean-filter"
              :label="option.label"
              :disabled="option.readonly || option.disabled"
              :color="optionsGroup.color"
              :model-value="(props.modelValue[k] as boolean)"
              @update:model-value="(v) => handleAddFilter(k, v as V)"
            />
          </template>
        </template>
      </div>
    </div>
  </AlgDisclosure>
</template>

<style scoped>
.filters {
  display: flex;
  flex: 1 1 auto;

  .filters-trigger {
    display: flex;
    height: var(--alg-font-size-3xl);
    flex: 1 1 auto;
    align-items: center;
    padding: var(--alg-spacing-s) 0;
    gap: var(--alg-spacing-s);

    .label {
      white-space: nowrap;
    }

    .chips {
      display: flex;
      max-height: 48px;
      box-sizing: border-box;
      flex-wrap: wrap;
      gap: var(--alg-spacing-xs);
      overflow-y: auto;
    }

    .reset-button {
      flex: 0 0 auto;
    }
  }

  .filters-group{
    display: flex;
    flex-direction: column;

    &:not(:last-child){
      margin-bottom: var(--alg-spacing-m)
    }
  }

  .filters-group-label {
    display: inline-flex;
    margin-bottom: var(--alg-spacing-xs);
    font-size: var(--alg-font-size-xs);
    font-weight: var(--alg-font-weight-bold);
    text-transform: uppercase
  }

  .filters-content {
    display: grid;
    gap: var(--alg-spacing-s);
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

    .boolean-filter {
      height: 40px;
    }
  }

  &:deep(.details-wrapper) {
    border-top: 1px solid var(--alg-color-surface-border);
  }
}
</style>
