<script
  setup
  lang="ts"
  generic="T extends (string | number | boolean | null) | (string | number | boolean | null)[] = boolean"
>
  import { computed } from 'vue'

  import { Size, Weight } from '#/types'

  import { AlgIcon } from '../../media'
  import { AlgLabel } from '../label'

  import { CheckboxSize } from './Checkbox.type'

  type Props = {
    readonly id: string
    readonly name?: string
    readonly modelValue: T
    readonly trueValue?: string | number | boolean | null
    readonly falseValue?: string | number | boolean | null
    readonly indeterminate?: boolean
    readonly disabled?: boolean
    readonly inactive?: boolean
    readonly label?: string
    readonly labelPosition?: 'start' | 'end'
    readonly labelFontWeight?: Weight
    readonly ariaLabel?: string
    readonly size?: CheckboxSize
    readonly clickable?: boolean
  }

  defineOptions({
    name: 'AlgCheckbox',
  })

  const props = withDefaults(defineProps<Props>(), {
    trueValue: true,
    falseValue: false,
    indeterminate: false,
    disabled: false,
    inactive: false,
    labelPosition: 'end',
    labelFontWeight: 'regular',
    size: 's',
    clickable: true,
  })

  const emit = defineEmits<{
    (e: 'update:model-value', p: T): void
  }>()

  // Computed
  const checkedValue = computed(() => {
    if (Array.isArray(props.modelValue)) {
      return props.modelValue.includes(props.trueValue)
    }

    return props.modelValue === props.trueValue
  })

  const toggle = computed({
    get() {
      return props.modelValue
    },
    set(value) {
      const isArray = Array.isArray(value) && value.length
      const updatedValue = (
        isArray ? value.filter((item, index) => value.indexOf(item) === index) : value
      ) as T
      emit('update:model-value', updatedValue)
    },
  })

  const computedLabelFontSize = computed(() =>
    props.size as Size,
  )

  const computedIconSize = computed(() =>
    props.size === 'm' ? 'xs' : props.size === 'l' ? 's' : 'xs',
  )
</script>

<template>
  <label
    class="checkbox-label"
    :class="{clickable: props.clickable}"
    :for="props.id"
  >
    <template v-if="props.label && props.labelPosition === 'start'">
      <AlgLabel
        :label="props.label"
        :font-size="computedLabelFontSize"
        :font-weight="props.labelFontWeight"
        :html-for="props.id"
        :disabled="props.disabled"
        inline
      />
    </template>
    <span
      class="checkbox"
      :class="[
        `size-${props.size}`, {
          checked: checkedValue,
          indeterminate: props.indeterminate,
          disabled: props.disabled,
          inactive: props.inactive,
        }
      ]"
    >
      <input
        :id="props.id"
        v-model="toggle"
        type="checkbox"
        tabindex="0"
        :aria-label="props.ariaLabel"
        :value="props.trueValue"
        :true-value="props.trueValue"
        :false-value="props.falseValue"
        :aria-disabled="props.inactive || props.disabled"
        :disabled="props.inactive || props.disabled"
      >
      <span class="checkbox-icon">
        <AlgIcon
          v-if="props.indeterminate"
          name="checkbox-indeterminate"
          :size="computedIconSize"
        />
        <AlgIcon
          v-else-if="checkedValue"
          name="checkbox-checked"
          :size="computedIconSize"
        />
      </span>
    </span>
    <template v-if="props.label && props.labelPosition === 'end'">
      <AlgLabel
        :label="props.label"
        :font-size="computedLabelFontSize"
        :font-weight="props.labelFontWeight"
        :html-for="props.clickable ? props.id : ''"
        :disabled="props.disabled"
        inline
      />
    </template>
  </label>
</template>

<style scoped>
.checkbox-label {
  display: inline-flex;
  cursor: inherit;
  gap: var(--alg-spacing-xs);

  &:not(.clickable){
    pointer-events: none;
  }

  .input-label {
    user-select: none;

    &:deep(.input-label-inner) {
      cursor: pointer;
      text-wrap: normal;
      white-space: wrap;
    }
  }

  .checkbox {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    border: 1px solid var(--alg-color-icon-unselected);
    border-radius: var(--alg-effect-radius-s);
    background-color: var(--alg-color-surface-primary);
    cursor: pointer;

    input[type='checkbox'] {
      width: 100%;
      height: 100%;
      margin: 0;
      appearance: none;
      aspect-ratio: 1;
      cursor: inherit;
    }

    .checkbox-icon {
      position: absolute;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      inset: 0;
    }

    &.size-s {
      width: 14px;
      height: 14px;
    }

    &.size-m {
      width: 18px;
      height: 18px;
    }

    &.size-l {
      width: 24px;
      height: 24px;
    }

    &.checked,
    &.indeterminate {
      border-color: var(--alg-color-surface-border);
      background-color: var(--alg-color-button-primary);
      color: var(--alg-color-surface-primary);
    }

    &.disabled {
      background-color: var(--alg-color-surface-border);
      cursor: not-allowed;

      &.checked,
      &.indeterminate {
        background-color: var(--alg-color-button-primary-disabled);
        color: var(--alg-color-surface-primary);
      }
    }

    &.inactive {
      cursor: default;
    }
  }
}
</style>
